import React, { FunctionComponent } from 'react';
import styles from './TransportationHeaderSummary.Styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import ServiceTypeRequestTransportationEntity from '../../../../../../entities/ServiceTypeRequestTransportation.Entity';
import OriginationEntity from '../../../../../../entities/Origination.Entity';
import AppointmentTypeEntity from '../../../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../../../entities/Destination.Entity';
import TransportationRequiredEntity from '../../../../../../entities/TransportationRequired.Entity';
import Moment from 'moment';
import { WheelchairOrStrecherDetailEntity } from '../../../../../../entities/WheelchairOrStrecherDetail.Entity';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../../../../generic-components/material-table/MaterialTable.Component';
import DestinationInformationEntity from '../../../../../../entities/DestinationInformation.Entity';
import StateEntity from '../../../../../../entities/State.Entity';
import moment from 'moment';

interface ITransportationHeaderSummaryProps {
    serviceTypeRequestTransportation: ServiceTypeRequestTransportationEntity;
    appointmentTypes: Array<AppointmentTypeEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    originations: Array<OriginationEntity>;
    destinations: Array<DestinationEntity>;
    wheelchairOrStretcherDetails: Array<WheelchairOrStrecherDetailEntity>;
    states: Array<StateEntity>;
}

export const TransportationHeaderSummary: FunctionComponent<ITransportationHeaderSummaryProps> = (
    props: ITransportationHeaderSummaryProps,
) => {
    const classes = styles();
    const abortController = new AbortController();

    const destinationsTableProps: MaterialTableProps<DestinationInformationEntity> = {
        columns: [
            {
                title: 'Destination',
                render: (rowData) => {
                    if (rowData.destinationId == 0) {
                        return 'Other';
                    } else {
                        return props.destinations.find((d) => d.destinationId === rowData.destinationId)
                            .destinationName;
                    }
                },
            },
            {
                title: 'Destination Address',
                render: (rowData) => {
                    return `${rowData?.destinationAddress || ''} ${rowData?.destinationCity || ''} ${
                        !!rowData?.destinationStateId ?
                            props.states?.filter(
                                (x) =>  x.stateId == rowData?.destinationStateId,
                            )[0]?.stateName || ''
                        :
                            ''
                    } ${rowData?.destinationZipCode || ''}`;
                },
            },
            {
                title: 'Destination Contact Number',
                field: 'destinationContactNumber',
            },
            {
                title: 'Appointment Time',
                render: (rowData) => {
                    return rowData?.appointmentTime?.length > 5
                        ? rowData?.appointmentTime?.split('T')[1].substring(0, 5)
                        : rowData?.appointmentTime;
                },
            },
            {
                title: 'Appointment Length (Min)',
                field: 'appointmentLength',
            },
            {
                title: 'Round-trip',
                render: (rowData) => {
                    return rowData?.roundTrip ? 'Yes' : 'No';
                },
            }
        ],
        data: props.serviceTypeRequestTransportation.destinations ?? [],
        options: {
            selection: false,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
    };

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {
                    <Typography variant="subtitle2" className={classes.sidebarTitle}>
                        Transportation Service
                    </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {/*row 1*/}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Transportation Required"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.transportationRequiredId !== 0
                                    ? props.transportationRequireds?.filter(
                                          (x) =>
                                              props.serviceTypeRequestTransportation &&
                                              props.serviceTypeRequestTransportation.transportationRequiredId &&
                                              x.transportationRequiredId ==
                                                  props.serviceTypeRequestTransportation.transportationRequiredId,
                                      )[0]?.transportationRequiredName
                                    : 'Other'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Other Transportation Required"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.transportationRequiredId === 0
                                    ? props.serviceTypeRequestTransportation?.otherTransportationRequired
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Wheelchair / Stretcher details"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.wheelchairOrStretcherDetails !== 0
                                    ? props.wheelchairOrStretcherDetails?.find(
                                          (x) =>
                                              props.serviceTypeRequestTransportation &&
                                              props.serviceTypeRequestTransportation.wheelchairOrStretcherDetails &&
                                              x.wheelchairOrStretcherDetailId ==
                                                  props.serviceTypeRequestTransportation.wheelchairOrStretcherDetails,
                                      )?.wheelchairOrStretcherDetailName
                                    : 'Other'
                            }
                        />
                    </Grid>
                    <Grid item xs={3} />

                    {/*row 2*/}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Appointment Date"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={Moment(props.serviceTypeRequestTransportation?.appointmentDate).format('MM/DD/YYYY')}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Rush Request
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.serviceTypeRequestTransportation?.rushRequest ? 'Yes' : 'No'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} >
                    <TooltipTextField
                            type="typography"
                            label="Frequency and Duration"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.frequencyAndDuration
                                    ? props.serviceTypeRequestTransportation?.frequencyAndDuration
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3} />

                    {/* Row 3 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Origination"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.originationId !== 0
                                    ? props.originations?.filter(
                                          (x) =>
                                              props.serviceTypeRequestTransportation &&
                                              props.serviceTypeRequestTransportation.originationId &&
                                              x.originationId == props.serviceTypeRequestTransportation.originationId,
                                      )[0]?.originationName
                                    : 'Other'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Facility Name"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.originationFacilityName
                                    ? props.serviceTypeRequestTransportation?.originationFacilityName
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Origination Contact Number"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.originationContactNumber
                                    ? props.serviceTypeRequestTransportation?.originationContactNumber
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3} />

                    {/* Row 4 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Origination Address"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.originationAddress
                                    ? props.serviceTypeRequestTransportation?.originationAddress
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="City"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.originationCity
                                    ? props.serviceTypeRequestTransportation?.originationCity
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            State
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {
                                props.states?.filter(
                                    (x) =>
                                        props.serviceTypeRequestTransportation &&
                                        x.stateId == props.serviceTypeRequestTransportation?.originationStateId,
                                )[0]?.stateName
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Zip Code"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.originationZipCode
                                    ? props.serviceTypeRequestTransportation?.originationZipCode
                                    : '-'
                            }
                        />
                    </Grid>

                    {/* Row 5 */}
                    <Grid item xs={12}>
                        <MaterialTable tableProps={destinationsTableProps} />
                    </Grid>

                    {/* Row 6 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Additional Passengers"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.additionalPassengers
                                    ? props.serviceTypeRequestTransportation?.additionalPassengers
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Number of Steps"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.numberOfSteps
                                    ? props.serviceTypeRequestTransportation?.numberOfSteps
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3} />

                    {/* Row 7 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Rental"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={props.serviceTypeRequestTransportation?.rental ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item container spacing={0} xs={3}>
                        <Grid item xs={6}>
                            <TooltipTextField
                                type="typography"
                                label="Oxygen"
                                typographyLabelClass={classes.dataLabel}
                                typographyValueClass={classes.blackFont}
                                value={props.serviceTypeRequestTransportation?.oxygen ? 'Yes' : 'No'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TooltipTextField
                                type="typography"
                                label="Oxygen Level"
                                typographyLabelClass={classes.dataLabel}
                                typographyValueClass={classes.blackFont}
                                value={props.serviceTypeRequestTransportation?.oxygenLevel ?? '-'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Medical Monitoring"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={props.serviceTypeRequestTransportation?.medicalMonitoring ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item xs={3} />

                    {/* Row 8 */}
                    <Grid item xs={3}>
                        <Typography>Claimant information:</Typography>
                    </Grid>
                    <Grid item container xs={3} spacing={0}>
                        <Grid item xs={6}>
                            <TooltipTextField
                                type="typography"
                                label="Height"
                                typographyLabelClass={classes.dataLabel}
                                typographyValueClass={classes.blackFont}
                                value={
                                    props.serviceTypeRequestTransportation?.heightFt
                                        ? `${props.serviceTypeRequestTransportation?.heightFt} feet ${
                                              props.serviceTypeRequestTransportation?.heightInches
                                                  ? `${props.serviceTypeRequestTransportation?.heightInches} inches`
                                                  : ''
                                          }`
                                        : '-'
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Weight"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.weightLbs
                                    ? `${props.serviceTypeRequestTransportation?.weightLbs} lbs`
                                    : '-'
                            }
                        />
                    </Grid>

                    {/*Row 9 */}
                    <Grid item xs={12}>
                        <TooltipTextField
                            type="typography"
                            label="Special Instructions"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTransportation?.specialInstructions
                                    ? props.serviceTypeRequestTransportation?.specialInstructions
                                    : '-'
                            }
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
