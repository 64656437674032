import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider, TextField } from '@material-ui/core';
import styles from "./ImeRequestSummary.Styles";
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImeEntity from '../../../../../../entities/Ime.Entity';
import SpecialtyEntity from '../../../../../../entities/Specialty.Entity';
import LanguageEntity from '../../../../../../entities/Language.Entity';
import SpecificBodyPartEntity from '../../../../../../entities/SpecificBodyPart.Entity';
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";

interface IImeRequestSummaryProps {
    imeRequest: ImeEntity,
    specialties: Array<SpecialtyEntity>,
    languages: Array<LanguageEntity>,
    specificBodyParts: Array<SpecificBodyPartEntity>
}

export const ImeRequestSummary: FunctionComponent<IImeRequestSummaryProps> = (props: IImeRequestSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>IME</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.imeRequest && props.specialties && props.languages ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        type='typography'
                                        label='Specialty'
                                        typographyLabelClass={classes.dataLabel}
                                        typographyValueClass={classes.blackFont}
                                        value={!!props.imeRequest.specialtyId ? props.specialties.filter(x => props.imeRequest && props.imeRequest.specialtyId && x.specialtyId == props.imeRequest.specialtyId)[0]?.specialtyName : props.imeRequest?.specialtyOther}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        type='typography'
                                        label='Impairment Rating Needed'
                                        typographyLabelClass={classes.dataLabel}
                                        typographyValueClass={classes.blackFont}
                                        value={props.imeRequest.impairmentRatingNeeded ? 'Yes' : 'No'}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    type='typography'
                                    label='Language'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.imeRequest.languageId !== 0 ? props.languages.filter(x => props.imeRequest && props.imeRequest.languageId && x.languageId == props.imeRequest.languageId)[0]?.languageName : 'Other'}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.imeRequest?.rushRequest ? 'Yes' : 'No' }</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        type='typography'
                                        label='Specific Body Parts'
                                        typographyLabelClass={classes.dataLabel}
                                        typographyValueClass={classes.blackFont}
                                        value={props.specificBodyParts?.map(x => x.specificBodyPartName).join(', ')}
                                />
                            </Grid>
                            <Grid item container xs={4}>
                                <Grid item xs={6}>
                                    <TooltipTextField
                                        type='typography'
                                        label='Patient will be using transportation'
                                        typographyLabelClass={classes.dataLabel}
                                        typographyValueClass={classes.blackFont}
                                        value={props.imeRequest.transportation ? 'Yes' : 'No'}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TooltipTextField
                                        type='typography'
                                        label='Patient will be using a translator'
                                        typographyLabelClass={classes.dataLabel}
                                        typographyValueClass={classes.blackFont}
                                        value={props.imeRequest.translation ? 'Yes' : 'No'}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                {props.imeRequest.languageId === 0 && 
                                    <TooltipTextField
                                        type='typography'
                                        label='Other language'
                                        typographyLabelClass={classes.dataLabel}
                                        typographyValueClass={classes.blackFont}
                                        value={props.imeRequest.languageId === 0 ? props.imeRequest.otherLanguage : ''}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                {/* Empty space */}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                        type='typography'
                                        label='Reason'
                                        maxRows={6}
                                        multiline
                                        className={classes.input}
                                        disabled
                                        InputProps={{
                                                disableUnderline:true,
                                        }}
                                        value={props.imeRequest.reasonForIME ? props.imeRequest.reasonForIME : 'No reason'}
                                />
                                <Divider className={classes.divider}></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                        label='History of Complaint'
                                        maxRows={6}
                                        multiline
                                        className={classes.input}
                                        disabled
                                        InputProps={{
                                                disableUnderline:true,
                                        }}
                                        value={props.imeRequest.historyOfComplaint ? props.imeRequest.historyOfComplaint : 'No history'}
                                />
                                <Divider className={classes.divider}></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                        label='Any Case Management History'
                                        maxRows={6}
                                        multiline
                                        className={classes.input}
                                        disabled
                                        InputProps={{
                                                disableUnderline:true,
                                        }}
                                        value={props.imeRequest.anyCaseManagementHistory ? props.imeRequest.anyCaseManagementHistory : 'No history'}
                                />
                                <Divider className={classes.divider}></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                        label='Other Comments'
                                        maxRows={6}
                                        multiline
                                        className={classes.input}
                                        disabled
                                        InputProps={{
                                                disableUnderline:true,
                                        }}
                                        value={props.imeRequest.otherComments ? props.imeRequest.otherComments : 'No comments'}
                                />
                                <Divider className={classes.divider}></Divider>
                            </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}