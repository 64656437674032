import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from './TransportationHeader.Styles';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
    Input,
    FormHelperText,
    TextField,
    Divider,
} from '@material-ui/core';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import ServiceTypeEntity from '../../../../../../entities/ServiceType.Entity';
import { useValidationDialog } from '../../../../../../support/custom-hooks/useValidationDialog';
import ServiceTypeRequestTransportationEntity from '../../../../../../entities/ServiceTypeRequestTransportation.Entity';
import TransportationRequiredEntity from '../../../../../../entities/TransportationRequired.Entity';
import OriginationEntity from '../../../../../../entities/Origination.Entity';
import DestinationEntity from '../../../../../../entities/Destination.Entity';
import Moment from 'moment';
import TextfieldMask from '../../../../../generic-components/texfield-mask/TextfieldMask.Component';
import { WheelchairOrStrecherDetailEntity } from '../../../../../../entities/WheelchairOrStrecherDetail.Entity';
import DestinationInformationEntity from '../../../../../../entities/DestinationInformation.Entity';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../../../../generic-components/material-table/MaterialTable.Component';
import { ITtReferralFormErrors } from '../TtReferralForm.Component';
import deleteDestinationInformation from '../../../../../../services/DestinationInformation.Service';
import { toast } from 'react-toastify';
import StateEntity from '../../../../../../entities/State.Entity';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import SearchAddressAsync, { AddressValue } from '../../../../../../components/generic-components/search-autocomplete/SearchAddressAsync'
import AddressAutocompleteEntity from '../../../../../../entities/ReferralAddress.Entity';

interface ITransportationHeaderProps {
    referralId: number;
    states: Array<StateEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    originations: Array<OriginationEntity>;
    destinations: Array<DestinationEntity>;
    errors: ITtReferralFormErrors;
    validate: (isChange?: boolean, isSubmission?: boolean) => void;
    handleSetIsValidTtDestinationFunction: (currentPageIsValidTtDestinationFunction: () => boolean) => void;
    hasBeenSubmitted: boolean;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    serviceTypes: Array<ServiceTypeEntity>;
    serviceTypeRequestTransportation: ServiceTypeRequestTransportationEntity;
    handleServiceTypeRequestTransportationEdit: any;
    wheelchairOrStrecherDetail: Array<WheelchairOrStrecherDetailEntity>;
}

export interface ITransportationHeaderErrors {
    //row 1
    appointmentDate?: string;
    //row 3
    transportationRequired?: string;
    //row 4
    origination?: string;
    originationContactNumber?: string;
    //row 6
    destinations?: string;
    frequencyAndDuration?: string;
}

interface IDestinationErrors {
    destinationId?: string;
}

export const TransportationHeader: FunctionComponent<ITransportationHeaderProps> = (
    props: ITransportationHeaderProps,
) => {
    const classes = styles();
    const abortController = new AbortController();

    const transportationRequireds = [
        ...props?.transportationRequireds ?? [],
        { transportationRequiredId: 0, transportationRequiredName: 'Other' },
    ];
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);

    const originations = [...props.originations ?? [], { originationId: 0, originationName: 'Other' }];
    const destinations = [...props.destinations ?? [], { destinationId: 0, destinationName: 'Other' }];

    const [serviceTypeRequestTransportation, setServiceTypeRequestTransportation] = React.useState<
        ServiceTypeRequestTransportationEntity
    >(props.serviceTypeRequestTransportation);

    const [counterState, setCounterState] = React.useState(
        props.serviceTypeRequestTransportation ? props.serviceTypeRequestTransportation : null,
    );

    const [newDestination, setNewDestination] = React.useState<DestinationInformationEntity>(
        new DestinationInformationEntity(),
    );

    const [localServiceTypes, setLocalServiceTypes] = React.useState<Array<ServiceTypeEntity>>(null);
    const [errors, setErrors] = React.useState<IDestinationErrors>(null);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');
    const [transportationFullAddress, setTransportationFullAddress] = React.useState("");
    const [destinationFullAddress, setDestinationFullAddress] = React.useState("");

    const passAddress = (address:AddressAutocompleteEntity) => {
        
        let newTranslationRequest: ServiceTypeRequestTransportationEntity = serviceTypeRequestTransportation;
        newTranslationRequest["originationCity"] = address.city;
        newTranslationRequest["originationAddress"] = `${address.streetNumber? address.streetNumber:''} ${address.streetName} ${address?.address2 ?? ""}`.trim();
        newTranslationRequest["originationZipCode"] = address.zipCode;

        const stateItem = props.states?.find(element => element.stateName==address.stateName);
        newTranslationRequest["originationStateId"] = stateItem?.stateId;
        
        setTransportationFullAddress(address.fullAddress);  
        setServiceTypeRequestTransportation(newTranslationRequest);
      }
      const passAddressDestination = (address:AddressAutocompleteEntity) => {
        
        let newdestinationRequest: DestinationInformationEntity = newDestination;
        newdestinationRequest["destinationCity"] = address.city;
        newdestinationRequest["destinationAddress"] = `${address.streetNumber? address.streetNumber:''} ${address.streetName?address.streetName:""} ${address?.address2 ?? ""}`.trim();
        newdestinationRequest["destinationZipCode"] = address.zipCode;

        const stateItem = props.states?.find(element => element.stateName==address.stateName);
        newdestinationRequest["destinationStateId"] = stateItem?.stateId;
        
        setDestinationFullAddress(address.fullAddress);  
        setNewDestination(newdestinationRequest);
      }

      const handleClear = ()=>{
        
        let newTranslationRequest: ServiceTypeRequestTransportationEntity = serviceTypeRequestTransportation
        newTranslationRequest["originationCity"] = undefined
        newTranslationRequest["originationAddress"] = undefined
        newTranslationRequest["originationZipCode"] = undefined
        newTranslationRequest["originationStateId"] = undefined;

        setTransportationFullAddress("");
        setServiceTypeRequestTransportation(newTranslationRequest);
    
    }

    const handleClearDestination = ()=>{
        
        let newdestinationRequest: DestinationInformationEntity = newDestination
        newdestinationRequest["destinationCity"] = undefined
        newdestinationRequest["destinationAddress"] = undefined
        newdestinationRequest["destinationZipCode"] = undefined
        newdestinationRequest["destinationStateId"] = undefined;

        setDestinationFullAddress("");
        setNewDestination(newdestinationRequest);
    }

    //componentDidMount
    useEffect(() => {
        props.handleSetIsValidTtDestinationFunction(isValidTtDestination);

        if (props.serviceTypeRequestTransportation) {
            const newSTTR: ServiceTypeRequestTransportationEntity = props.serviceTypeRequestTransportation;
            setServiceTypeRequestTransportation(newSTTR);
            setCounterState(newSTTR);
        } else {
            const newSTTR: ServiceTypeRequestTransportationEntity = new ServiceTypeRequestTransportationEntity();
            newSTTR.referralId = props.referralId;
            setServiceTypeRequestTransportation(newSTTR);
            setCounterState(newSTTR);
        }

        if (!localServiceTypes && props.serviceTypes) {
            setLocalServiceTypes(props.serviceTypes);
        }

        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    useEffect(() => {
        if(serviceTypeRequestTransportation?.originationStateId)
        {
            const stateItem = props.states?.find(element => element.stateId==serviceTypeRequestTransportation.originationStateId);
            
            setTransportationFullAddress(MakeTransportionFullAddress(serviceTypeRequestTransportation,stateItem.stateName));
        }
    }, [serviceTypeRequestTransportation]);

    const MakeTransportionFullAddress = (tranlationState : ServiceTypeRequestTransportationEntity,stateName:string) =>
    `${ tranlationState.originationAddress } ${tranlationState.originationCity?tranlationState.originationCity+", ":""} ${stateName? `${stateName} `:"" }${ tranlationState.originationZipCode? tranlationState.originationZipCode:""}`


    const handleServiceTypeRequestTransportation = (event) => {
        event.persist();

        const newSTTR: ServiceTypeRequestTransportationEntity = serviceTypeRequestTransportation;

        if (!newSTTR.referralId) {
            newSTTR.referralId = props.referralId;
        }

        if (event.target.type == 'checkbox') {
            setCounterState({
                ...counterState,
                [event.target.id ? event.target.id : event.target.name]: event.target.checked,
            });
            newSTTR[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        } else {
            setCounterState({
                ...counterState,
                [event.target.id ? event.target.id : event.target.name]: event.target.value,
            });
            newSTTR[event.target.id ? event.target.id : event.target.name] =
                event.target.value == 'true'
                    ? true
                    : event.target.value == 'false'
                    ? false
                    : event.target.value?.toString() == 'true'
                    ? true
                    : event.target.value?.toString() == 'false'
                    ? false
                    : event.target.value;
        }

        if (newSTTR.transportationRequiredId !== 0) {
            newSTTR.otherTransportationRequired = null;
        }

        if (!newSTTR.oxygen) {
            newSTTR.oxygenLevel = null;
        }

        setServiceTypeRequestTransportation(newSTTR);

        props.handleServiceTypeRequestTransportationEdit(serviceTypeRequestTransportation);

        if (props.hasBeenSubmitted) props.validate(true, false);
    };

    const handleChangeNewDestination = (event) => {
        event.persist();

        const newD: DestinationInformationEntity = { ...newDestination };

        if (event.target.type == 'checkbox') {
            setCounterState({
                ...counterState,
                [event.target.id ? event.target.id : event.target.name]: event.target.checked,
            });
            newD[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        } else {
            setCounterState({
                ...counterState,
                [event.target.id ? event.target.id : event.target.name]: event.target.value,
            });

        newD[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true'
                ? true
                : event.target.value == 'false'
                ? false
                : event.target.value?.toString() == 'true'
                ? true
                : event.target.value?.toString() == 'false'
                ? false
                : event.target.value;
        }
        setNewDestination(newD);

        if (hasBeenSubmitted) {
            localValidate(true);
        }
    };

    const destinationsTableProps: MaterialTableProps<DestinationInformationEntity> = {
        columns: [
            {
                title: 'Destination',
                render: (rowData) => {
                    if (rowData.destinationId == 0) {
                        return 'Other';
                    } else {
                        return props.destinations.find((d) => d.destinationId === rowData.destinationId)
                            .destinationName;
                    }
                },
            },
            {
                title: 'Destination Address',
                render: (rowData) => {
                    return `${!!rowData?.destinationAddress ? rowData?.destinationAddress : ''} ${
                        !!rowData?.destinationCity ? rowData?.destinationCity : ''
                    } ${
                        !!rowData?.destinationStateId ?
                            props.states?.filter(
                                (x) => x.stateId == rowData?.destinationStateId
                            )[0]?.stateName || ''
                        :
                            ''
                    } ${!!rowData?.destinationZipCode ? rowData?.destinationZipCode : ''}`;
                },
            },
            {
                title: 'Destination Contact Number',
                field: 'destinationContactNumber',
            },
            {
                title: 'Appointment Time',
                render: (rowData) => {
                    return rowData?.appointmentTime?.length > 5
                        ? rowData?.appointmentTime?.split('T')[1].substring(0, 5)
                        : rowData?.appointmentTime;
                },
            },
            {
                title: 'Appointment Length (Min)',
                field: 'appointmentLength',
            },
            {
                title: 'Round-trip',
                render: (rowData) => {
                    return rowData?.roundTrip ? 'Yes' : 'No';
                },
            }
        ],
        data: serviceTypeRequestTransportation.destinations ?? [],
        options: {
            selection: false,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
        actions: [
            {
                icon: 'delete',
                tooltip: 'delete detail',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    handleDeleteRow(rowData);
                },
            },
        ],
    };

    const handleDeleteRow = (destination: DestinationInformationEntity) => {
        const newSTTR: ServiceTypeRequestTransportationEntity = { ...serviceTypeRequestTransportation };
        newSTTR.destinations.splice(newSTTR.destinations.indexOf(destination), 1);
        
        if (destination.destinationInformationId) {
            deleteDestinationInformation(destination.destinationInformationId, abortController.signal)
                .then(() => {
                    toast.success('Destination deleted successfully.');
                })
                .catch(() => {
                    toast.error('Error deleting destination.');
                });
        }

        setServiceTypeRequestTransportation({ ...newSTTR });
        props.handleServiceTypeRequestTransportationEdit(serviceTypeRequestTransportation);
    };

    const handleAddNewDestination = () => {
        if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true);
        }

        if (!localValidate()) {
            return;
        }

        const newSTTR: ServiceTypeRequestTransportationEntity = { ...serviceTypeRequestTransportation };

        if (newSTTR.destinations) {
            newSTTR.destinations.push(newDestination);
        } else {
            newSTTR.destinations = [newDestination];
        }

        setServiceTypeRequestTransportation({ ...newSTTR });
        props.handleServiceTypeRequestTransportationEdit({
            ...serviceTypeRequestTransportation,
            destinations: newSTTR.destinations,
        });
        setNewDestination(new DestinationInformationEntity());

        setDestinationFullAddress("");
        
    };

    const isValidTtDestination = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IDestinationErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListTtDestination();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListTtDestination = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IDestinationErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if (!(serviceTypeRequestTransportation?.destinations?.length > 0 )) {
            errors.destinationId = 'Required';
        }
        return errors;
    }

    const localValidate = (isChange?: boolean) => {
        if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true);
        }
        const errors: IDestinationErrors = {};
        errors.destinationId =
            newDestination && newDestination.destinationId !== null && newDestination.destinationId !== undefined
                ? ''
                : 'Required';

        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const minutesAppointment = [15,30,45,60,75,90,105,120];

    return (
        <Fragment>
            {localServiceTypes ? (
                <Grid container spacing={4} className={classes.gridContainer}>
                    {/* Row 1 */}
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                type="select"
                                formControlClass={classes.selectInput}
                                label="Transportation Required"
                                inputLabelId="demo-simple-select-label"
                                id="transportationRequiredId"
                                name="transportationRequiredId"
                                key={serviceTypeRequestTransportation?.transportationRequiredId}
                                value={serviceTypeRequestTransportation?.transportationRequiredId}
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                zeroIsValid
                                required
                                error={!!props.errors?.transportationRequired}
                            >
                                {transportationRequireds?.map((transportationRequired, index) => {
                                    return (
                                        <MenuItem
                                            key={transportationRequired.transportationRequiredId}
                                            value={transportationRequired.transportationRequiredId}
                                        >
                                            {transportationRequired.transportationRequiredName}
                                        </MenuItem>
                                    );
                                })}
                            </TooltipTextField>
                            <FormHelperText>{props?.errors?.transportationRequired}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                label="Other Transportation Required"
                                id="otherTransportationRequired"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                disabled={
                                    !!serviceTypeRequestTransportation.transportationRequiredId ||
                                    serviceTypeRequestTransportation.transportationRequiredId !== 0
                                }
                                value={
                                    serviceTypeRequestTransportation?.otherTransportationRequired
                                        ? serviceTypeRequestTransportation?.otherTransportationRequired
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="select"
                            formControlClass={classes.selectInput}
                            label="Wheelchair / Stretcher details"
                            inputLabelId="demo-simple-select-label"
                            id="wheelchairOrStretcherDetails"
                            name="wheelchairOrStretcherDetails"
                            key={serviceTypeRequestTransportation?.wheelchairOrStretcherDetails}
                            value={serviceTypeRequestTransportation?.wheelchairOrStretcherDetails}
                            onChange={(event) => handleServiceTypeRequestTransportation(event)}
                            zeroIsValid
                        >
                            {props?.wheelchairOrStrecherDetail?.map((wh, index) => {
                                return (
                                    <MenuItem
                                        key={wh.wheelchairOrStretcherDetailId}
                                        value={wh.wheelchairOrStretcherDetailId}
                                    >
                                        {wh.wheelchairOrStretcherDetailName}
                                    </MenuItem>
                                );
                            })}
                        </TooltipTextField>
                    </Grid>
                    <Grid item xs={3} />

                    {/* Row 2 */}
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Appointment Date"
                                id="appointmentDate"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                defaultValue={
                                    serviceTypeRequestTransportation?.appointmentDate
                                        ? Moment(serviceTypeRequestTransportation?.appointmentDate).format('YYYY-MM-DD')
                                        : ''
                                }
                                error={!!props.errors?.appointmentDate}
                                required
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                            />
                            <FormHelperText>{props?.errors?.appointmentDate}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={<Typography variant="body2">Rush Request</Typography>}
                                control={
                                    <Checkbox
                                        key={serviceTypeRequestTransportation?.rushRequest == null ? 'false' : 'true'}
                                        id="rushRequest"
                                        name="rushRequest"
                                        disableRipple
                                        checked={serviceTypeRequestTransportation?.rushRequest}
                                        onClick={(event) => handleServiceTypeRequestTransportation(event)}
                                        style={{marginLeft:'-3px'}}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            label="Frequency and Duration"
                            id="frequencyAndDuration"
                            onChange={(event) => handleServiceTypeRequestTransportation(event)}
                            value={
                                serviceTypeRequestTransportation?.frequencyAndDuration
                                    ? serviceTypeRequestTransportation?.frequencyAndDuration
                                    : ''
                            }                 
                            required
                            error={!!props.errors?.frequencyAndDuration}                            
                        />
                        <FormHelperText>{props?.errors?.frequencyAndDuration}</FormHelperText>
                        </Grid>
                    <Grid item xs={3} />
                
                    {/* Row 3 */}
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                type="select"
                                formControlClass={classes.selectInput}
                                label="Origination"
                                inputLabelId="demo-simple-select-label"
                                id="originationId"
                                name="originationId"
                                key={serviceTypeRequestTransportation?.originationId}
                                value={serviceTypeRequestTransportation?.originationId}
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                zeroIsValid
                                required
                                error={!!props?.errors?.origination}
                            >
                                {originations?.map((origination, index) => {
                                    return (
                                        <MenuItem key={origination.originationId} value={origination.originationId}>
                                            {origination.originationName}
                                        </MenuItem>
                                    );
                                })}
                            </TooltipTextField>
                            <FormHelperText>{props?.errors?.origination}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                label="Facility Name"
                                id="originationFacilityName"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                value={
                                    serviceTypeRequestTransportation?.originationFacilityName
                                        ? serviceTypeRequestTransportation?.originationFacilityName
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TextfieldMask
                            label="Origination Contact Number"
                            id="originationContactNumber"
                            onChange={(event) => handleServiceTypeRequestTransportation(event)}
                            value={
                                serviceTypeRequestTransportation?.originationContactNumber
                                    ? serviceTypeRequestTransportation?.originationContactNumber
                                    : ''
                            }
                            mask="(999)-9999999"
                            error={!!props.errors?.originationContactNumber}
                            required
                        />
                        <FormHelperText>{props?.errors?.originationContactNumber}</FormHelperText>
                    </Grid>

                    <Grid item xs={3} />

                    {/* Row 4 */}
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <SearchAddressAsync passAddress={passAddress} states={props.states} />                            
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <AddressValue 
                                key="TransportationSearchAddressResult" 
                                id="TransportationSearchAddressResult" 
                                address={transportationFullAddress} 
                                handleClear={handleClear} 
                            />                       
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                    <TooltipTextField
                                label="Don’t forget to add an apt. or suite number"
                                id="originationAddressLine2"
                                InputLabelProps={{ shrink: true }}
                                placeholder = "Address line 2 apt # suite #"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                value={
                                    serviceTypeRequestTransportation?.originationAddressLine2
                                        ? serviceTypeRequestTransportation?.originationAddressLine2
                                        : ''
                                }
                            />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>

                    {/* Row 5 */}
                    <Grid item xs={3} spacing={3} container>
                        <Grid item xs={12}>
                            <TooltipTextField
                                type="select"
                                formControlClass={classes.selectInput}
                                label="Destination"
                                inputLabelId="demo-simple-select-label"
                                id="destinationId"
                                name="destinationId"
                                key={newDestination?.destinationId}
                                value={newDestination?.destinationId}
                                onChange={(event) => handleChangeNewDestination(event)}
                                zeroIsValid
                                required={!!errors?.destinationId}
                                error={!!errors?.destinationId}
                            >
                                {destinations?.map((destination, index) => {
                                    return (
                                        <MenuItem key={destination.destinationId} value={destination.destinationId}>
                                            {destination.destinationName}
                                        </MenuItem>
                                    );
                                })}
                            </TooltipTextField>
                            <FormHelperText>{errors?.destinationId}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} spacing={3} container>
                        <Grid item xs={12}>
                            <TooltipTextField
                                label="Facility Name"
                                id="destinationFacilityName"
                                onChange={(event) => handleChangeNewDestination(event)}
                                value={
                                    newDestination?.destinationFacilityName
                                        ? newDestination?.destinationFacilityName
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TextfieldMask
                            label="Destination Contact Number"
                            id="destinationContactNumber"
                            onChange={(event) => handleChangeNewDestination(event)}
                            value={
                                newDestination?.destinationContactNumber ? newDestination?.destinationContactNumber : ''
                            }
                            mask="(999)-9999999"
                        />
                    </Grid>
                    <Grid item xs={3} />

                    {/* Row 6 */}
                    <Grid item xs={3} spacing={3} container>
                        <Grid item xs={12}>
                            <SearchAddressAsync passAddress={passAddressDestination} states={props.states} />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} spacing={3} container>
                        <Grid item xs={12}>
                            <AddressValue 
                                key="DestinationSearchAddressResult" 
                                id="DestinationSearchAddressResult" 
                                address={destinationFullAddress} 
                                handleClear={handleClearDestination} 
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                    <TooltipTextField
                                label="Don’t forget to add an apt. or suite number"
                                id="destinationAddressLine2"
                                placeholder = "Address line 2 apt # suite #"
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => handleChangeNewDestination(event)}
                                value={
                                    newDestination?.destinationAddressLine2
                                        ? newDestination?.destinationAddressLine2
                                        : ''
                                }
                            />
                    </Grid>
                    <Grid item xs={3}>
                        
                    </Grid>

                    {/* Row 7 */}
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                label="Appointment Time"
                                id="appointmentTime"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => handleChangeNewDestination(event)}
                                value={newDestination?.appointmentTime ? newDestination?.appointmentTime : ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                type="select"
                                formControlClass={classes.selectInput}
                                label="Appointment Length (Min)"
                                id="appointmentLength"
                                inputLabelId="demo-simple-select-label"                                
                                name="appointmentLength"
                                key={newDestination?.appointmentLength}
                                onChange={(event) => handleChangeNewDestination(event)}
                                value={newDestination?.appointmentLength ? newDestination?.appointmentLength : ''}
                                >
                                {minutesAppointment?.map((minute, index) => {
                                    return (
                                        <MenuItem
                                            key={minute}
                                            value={minute}
                                        >
                                           {minute}
                                        </MenuItem>
                                    );
                                })}
                            </TooltipTextField>
                        </Grid>
                    </Grid>

                    <Grid item xs={3} container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                            <FormControlLabel
                                label={<Typography variant="body2">Round-trip</Typography>}
                                control={
                                    <Checkbox
                                        key={newDestination?.roundTrip == null ? 'false' : 'true'}
                                        id="roundTrip"
                                        name="roundTrip"
                                        disableRipple
                                        checked={newDestination?.roundTrip}
                                        onClick={(event) => handleChangeNewDestination(event)}
                                    />
                                }
                            />
                            </Grid>
                    </Grid>
                        
                    <Grid item xs={3}>
                        <Button className={classes.button} onClick={handleAddNewDestination}>
                            ADD
                        </Button>
                    </Grid>
                    

                    {/* Row 8 */}
                    <Grid item xs={12}>
                        <MaterialTable tableProps={destinationsTableProps} />
                    </Grid>

                    {/* Row 9 */}
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                label="Additional Passengers"
                                id="additionalPassengers"
                                type="number"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                value={
                                    serviceTypeRequestTransportation?.additionalPassengers
                                        ? serviceTypeRequestTransportation?.additionalPassengers
                                        : ''
                                }
                                onInput={(e) => {
                                    (e.target as HTMLInputElement).value = Math.max(
                                        0,
                                        parseInt((e.target as HTMLInputElement).value),
                                    )
                                        .toString()
                                        .slice(0, 7);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={3}>
                        <Grid item xs={12}>
                            <TooltipTextField
                                label="Number of Steps"
                                id="numberOfSteps"
                                type="number"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                value={
                                    serviceTypeRequestTransportation?.numberOfSteps
                                        ? serviceTypeRequestTransportation?.numberOfSteps
                                        : ''
                                }
                                onInput={(e) => {
                                    (e.target as HTMLInputElement).value = Math.max(
                                        0,
                                        parseInt((e.target as HTMLInputElement).value),
                                    )
                                        .toString()
                                        .slice(0, 7);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} />

                    {/* Row 10 */}
                    <Grid item xs={3}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Rental</FormLabel>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="rental"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                value={serviceTypeRequestTransportation?.rental ? true : false}
                            >
                                <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item container spacing={0} xs={3}>
                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Oxygen</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="position"
                                    name="oxygen"
                                    onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                    value={serviceTypeRequestTransportation?.oxygen ? true : false}
                                >
                                    <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TooltipTextField
                                label="Oxygen Level"
                                id="oxygenLevel"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                disabled={!serviceTypeRequestTransportation?.oxygen}
                                value={
                                    serviceTypeRequestTransportation?.oxygenLevel
                                        ? serviceTypeRequestTransportation?.oxygenLevel
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Medical Monitoring</FormLabel>
                            <RadioGroup
                                row
                                aria-label="position"
                                name="medicalMonitoring"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                value={serviceTypeRequestTransportation?.medicalMonitoring ? true : false}
                            >
                                <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3} />

                    {/* Row 11 */}
                    <Grid item xs={3}>
                        <Typography>Claimant information:</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <FormControl>
                            <InputLabel htmlFor="filled-adornment-amount">Height</InputLabel>
                            <Input
                                id="heightFt"
                                name="heightFt"
                                value={serviceTypeRequestTransportation?.heightFt}
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                type="number"
                                endAdornment={<InputAdornment position="end">Ft</InputAdornment>}
                                onInput={(e) => {
                                    (e.target as HTMLInputElement).value = Math.max(
                                        0,
                                        parseInt((e.target as HTMLInputElement).value),
                                    )
                                        .toString()
                                        .slice(0, 2);
                                }}
                                style={{ width: '100px' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <FormControl>
                            <InputLabel htmlFor="filled-adornment-amount"></InputLabel>
                            <Input
                                id="heightInches"
                                name="heightInches"
                                value={serviceTypeRequestTransportation?.heightInches}
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                type="number"
                                endAdornment={<InputAdornment position="end">Inches</InputAdornment>}
                                onInput={(e) => {
                                    (e.target as HTMLInputElement).value = Math.max(
                                        0,
                                        parseInt((e.target as HTMLInputElement).value),
                                    )
                                        .toString()
                                        .slice(0, 2);
                                }}
                                style={{ width: '100px' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <FormControl>
                            <InputLabel htmlFor="filled-adornment-amount" shrink={true}>
                                Weight
                            </InputLabel>
                            <Input
                                id="weightLbs"
                                name="weightLbs"
                                value={serviceTypeRequestTransportation?.weightLbs}
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                type="number"
                                endAdornment={<InputAdornment position="end">Lbs</InputAdornment>}
                                onInput={(e) => {
                                    (e.target as HTMLInputElement).value = Math.max(
                                        0,
                                        parseInt((e.target as HTMLInputElement).value),
                                    )
                                        .toString()
                                        .slice(0, 4);
                                }}
                                style={{ width: '100px' }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Row 12 */}
                    <Grid item xs={12}>
                        <FormControl className={classes.selectInput}>
                            <TooltipTextField
                                maxRows={4}
                                multiline={true}
                                label="Special Instructions"
                                id="specialInstructions"
                                name="specialInstructions"
                                onChange={(event) => handleServiceTypeRequestTransportation(event)}
                                defaultValue={
                                    serviceTypeRequestTransportation?.specialInstructions
                                        ? serviceTypeRequestTransportation?.specialInstructions
                                        : ''
                                }
                                inputProps={{ maxLength: 500 }}
                                helperText={`${
                                    counterState?.specialInstructions ? counterState?.specialInstructions.length : 0
                                }/${500}`}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            ) : (
                <EmptyDataLabel></EmptyDataLabel>
            )}
            {Dialog}
        </Fragment>
    );
};
