export default class DestinationInformationEntity {
    destinationInformationId: number;
    destinationId: number;
    destinationContactNumber: string;
    destinationAddress: string;
    destinationFacilityName: string;
    destinationCity: string;
    destinationStateId: number;
    destinationZipCode: string;
    appointmentTime: string;
    appointmentLength: string;
    destinationAddressLine2: string;
    roundTrip: boolean;
}
