import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import ClientPreferredVendorDataModel from '../../../data-models/ClientPreferredVendor.DataModel';
import ServiceDataModel from '../../../data-models/Service.DataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import StateEntity from '../../../entities/State.Entity';
import TenantEntity from '../../../entities/Tenant.Entity';
import styles from './ClientPreferredVendorSetup.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import { MaterialTableProps } from 'material-table';
import useArrayChanges from '../../../support/custom-hooks/useArrayChanges';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Confirm } from '../../generic-components/confirm/Confirm.Component';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { useParams } from 'react-router-dom';

interface ClientPreferredVendorSetupProps {
    tenants: Array<TenantEntity>;
    vendors: Array<VendorAdminTableDataModel>;
    states: Array<StateEntity>;
    services: Array<ServiceDataModel>;
    handleNewPreferredVendorEdit: (edited: ClientPreferredVendorDataModel) => void;
    newPreferredVendor: ClientPreferredVendorDataModel;
    handleNewPreferredVendorMultipleStateEdit: (edited: Array<number>, states: Array<StateEntity>) => void;
    preferredVendors: Array<ClientPreferredVendorDataModel>;
    handleCreateNewPreferredVendor: () => Promise<void>;
    tenantId: number;
    handleChangeClientPreferredVendorStatus: (id: Array<number>) => void;
    handleStartEditPreferredVendor: (
        tenantId: number,
        vendorId: number,
        serviceId: number,
        states: number[],
        clientPreferredVendorIds: number[],
    ) => void;
    handleConfirmEditPreferredVendor: (newStateIds: number[], removedStateIds: number[]) => void;
    handlConfirmUpdatePreferredVendor: (removedClientIds: number[]) => void;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    handleClearNewPreferredVendor: () => void;
    handleGetClientPreferredVendors: (clientId: string) => void;
}

interface IErrors {
    tenantId?: string;
    serviceId?: string;
    serviceTypeId?: string;
    vendorId?: string;
    stateId?: string;
}

export const ClientPreferredVendorSetup: FunctionComponent<ClientPreferredVendorSetupProps> = (props) => {
    const classes = styles();
    const [newPreferredVendorState, setNewPreferredVendorState] = React.useState<ClientPreferredVendorDataModel>();
    const abortController = new AbortController();

    const [states, setStates] = React.useState<StateEntity[]>([]);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<IErrors>(null);
    const [selectedRowToDelete, setSelectedRowToDelete] = React.useState<ClientPreferredVendorDataModel>(null);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState<boolean>(false);
    const [showAddError, setShowAddError] = React.useState<boolean>(false);
    const { updateItems, setItems } = useArrayChanges<number>(props.newPreferredVendor?.stateIds);
    const [originalPreferredVendor, setOriginalPreferredVendor] = React.useState<ClientPreferredVendorDataModel>();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { id } = useParams<{ id: string }>();

    React.useEffect(() => {
        setIsLoading(true);
        handleInitialLoad();
    }, []);

    const handleInitialLoad = async () => {
        props.handleGetClientPreferredVendors(id);
        setIsLoading(false);
    };

    React.useEffect(() => {
        let newServiceByVendor = props.newPreferredVendor;

        if (states.length === 0) {
            const newStates = [...props.states];
            newStates?.unshift({ stateId: 0, stateCode: 'All', stateName: 'All' });
            setStates(newStates);
        }

        if (!newServiceByVendor) {
            newServiceByVendor = {
                serviceId: null,
                stateIds: [0],
                tenantId: props.tenantId,
            } as ClientPreferredVendorDataModel;
            props.handleNewPreferredVendorEdit(newServiceByVendor);
        }

        setNewPreferredVendorState(newServiceByVendor);
        props.handleSetValidationFunction(validate);

        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    React.useEffect(() => {
        if (editMode) {
            setOriginalPreferredVendor({ ...newPreferredVendorState });
            setItems(newPreferredVendorState.stateIds);
        }
    }, [editMode]);

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        let editedPreferredVendorState: ClientPreferredVendorDataModel = { ...newPreferredVendorState };
        editedPreferredVendorState[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setNewPreferredVendorState(editedPreferredVendorState);
        props.handleNewPreferredVendorEdit(editedPreferredVendorState);
    };

    const handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.persist();
        const currentState = { ...newPreferredVendorState };
        currentState.stateIds = event.target.value as number[];
        setNewPreferredVendorState(currentState);
        props.handleNewPreferredVendorMultipleStateEdit(event.target.value as number[], states);
    };

    const validate = () => {
        let errors: IErrors = {};

        if (!hasBeenSubmitted) {
            setHasBeenSubmitted(true);
        }

        errors.serviceId = props.newPreferredVendor && props.newPreferredVendor.serviceId ? '' : 'Required';

        if (props.newPreferredVendor && props.newPreferredVendor.stateIds.length > 0) {
            if (props.newPreferredVendor.stateIds.includes(0) && props.newPreferredVendor.stateIds.length > 1) {
                errors.stateId = "You can't select 'All' and another option.";
            }
        } else {
            errors.stateId = 'Required';
        }

        setErrors(errors);

        return Object.values(errors).every((x) => x == '');
    };

    const preferredVendorsTableProps: MaterialTableProps<ClientPreferredVendorDataModel> = {
        columns: [
            {
                title: 'Service',
                render: (rowData) => {
                    const service = props.services.find((s) => s.service.serviceId === rowData.serviceId);
                    if (service) {
                        if (!service.service.isActive) {
                            return `${service.service.serviceName} (Inactive)`;
                        } else {
                            return service?.service?.serviceName;
                        }
                    }
                },
            },
            {
                title: 'Preferred Vendor',
                render: (rowData) => props?.vendors?.find((v) => v.vendorId === rowData.vendorId)?.vendorName,
            },
            {
                title: 'State',
                render: (rowData) => {
                    const statesToRender: string[] = [];
                    if (rowData.stateIds.some((s) => s === null)) {
                        return 'All';
                    }
                    states
                        .filter((s) => rowData.stateIds.includes(s.stateId))
                        .forEach((state) => {
                            statesToRender.push(state.stateName);
                        });

                    return statesToRender.join(', ');
                },
            },
        ],
        data: props.preferredVendors ?? [],
        options: {
            showTitle: true,
            paging: props?.preferredVendors ? props?.preferredVendors?.length > 10 : false,
            toolbar: true,
            actionsColumnIndex: -1,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions: [
            {
                icon: () => <EditIcon style={{ fill: 'rgba(0,0,0,0.54)' }} />,
                tooltip: 'Edit preferred vendor',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    props.handleStartEditPreferredVendor(
                        rowData.tenantId,
                        rowData.vendorId,
                        rowData.serviceId,
                        rowData.stateIds,
                        rowData.clientPreferredVendorIds,
                    );
                    setNewPreferredVendorState({
                        tenantId: rowData.tenantId,
                        vendorId: rowData.vendorId,
                        serviceId: rowData.serviceId,
                        stateIds: rowData.stateIds,
                        clientPreferredVendorIds: rowData.clientPreferredVendorIds,
                        isActive: true,
                    });
                    setEditMode(true);
                },
            },
            (rowData) => ({
                icon: () => <DeleteIcon style={{ fill: 'rgba(0,0,0,0.54)' }} />,
                tooltip: 'Delete preferred vendor',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    setSelectedRowToDelete(rowData);
                    setShowConfirmDelete(true);
                },
            }),
        ],
        title: 'Preferred Vendor Set up',
    };

    const handleConfirmEdit = async () => {
        if (
            newPreferredVendorState.vendorId !== originalPreferredVendor.vendorId ||
            newPreferredVendorState.serviceId !== originalPreferredVendor.serviceId
        ) {
            props.handlConfirmUpdatePreferredVendor(newPreferredVendorState.clientPreferredVendorIds);
        } else {
            const { addedItems, removedItems } = updateItems(props.newPreferredVendor.stateIds);
            props.handleConfirmEditPreferredVendor(addedItems, removedItems);
        }
        setEditMode(false);
    };

    const createNewPreferredVendor = async () => {
        setIsLoading(true);
        await props.handleCreateNewPreferredVendor().catch((e) => {
            setShowAddError(true);
        });

        setIsLoading(false);
    };

    if (!newPreferredVendorState) return null;

    return (
        <>
            {isLoading ? (
                <Loader isSmall />
            ) : (
                <>
                    <Paper>
                        <form noValidate autoComplete="off" className={classes.formContainer}>
                            <Typography variant="subtitle2" className={classes.paperTitle}>
                                Preferred Vendor Set Up
                            </Typography>
                            <Grid container spacing={3} className={classes.gridContainer}>
                                <Grid item xs={3}>
                                    <FormControl className={classes.selectInput}>
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            required
                                            error={!!errors?.serviceId}
                                            shrink={!!newPreferredVendorState.serviceId}
                                        >
                                            Service
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="serviceId"
                                            name="serviceId"
                                            value={newPreferredVendorState.serviceId}
                                            onChange={(event) => handleTextFieldChange(event)}
                                            required
                                            error={!!errors?.serviceId}
                                        >
                                            {props.services?.map((service, index) => {
                                                return (
                                                    <MenuItem
                                                        key={service.service.serviceId}
                                                        value={service.service.serviceId}
                                                    >
                                                        {service.service.serviceName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>{errors?.serviceId}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl className={classes.selectInput} key={newPreferredVendorState.vendorId}>
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            shrink={!!newPreferredVendorState.vendorId}
                                        >
                                            Preferred Vendor
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="vendorId"
                                            name="vendorId"
                                            value={newPreferredVendorState.vendorId}
                                            onChange={(event) => handleTextFieldChange(event)}
                                        >
                                            {props.vendors?.map((vendor, index) => {
                                                return (
                                                    <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                                        {vendor.vendorName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>{errors?.vendorId}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl className={classes.selectInput}>
                                        <InputLabel id="demo-simple-select-label" error={!!errors?.stateId}>
                                            States
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="stateId"
                                            name="stateId"
                                            multiple
                                            value={newPreferredVendorState.stateIds}
                                            input={<Input />}
                                            renderValue={(selected) =>
                                                (selected as number[])
                                                    .map((x) => states.filter((y) => y.stateId == x)[0].stateName)
                                                    .join(', ')
                                            }
                                            onChange={handleStateChange}
                                            error={!!errors?.stateId}
                                        >
                                            {states?.map((state, index) => {
                                                return (
                                                    <MenuItem key={state.stateId} value={state.stateId}>
                                                        {state.stateName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>{errors?.stateId}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} alignItems="center" alignContent="center" justify="center">
                                    <div className={classes.buttonsContainer}>
                                        {editMode ? (
                                            <div className={classes.buttonsContainer}>
                                                <Button
                                                    className={classes.cancelButton}
                                                    onClick={() => {
                                                        props.handleClearNewPreferredVendor();
                                                        setEditMode(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button className={classes.button} onClick={handleConfirmEdit}>
                                                    Save
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button className={classes.button} onClick={createNewPreferredVendor}>
                                                Add
                                            </Button>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                    <Paper>
                        {isLoading ? <Loader isSmall /> : <MaterialTable tableProps={preferredVendorsTableProps} />}
                    </Paper>
                    <Confirm
                        onAction={() => {
                            props.handleChangeClientPreferredVendorStatus(selectedRowToDelete.clientPreferredVendorIds);
                            setShowConfirmDelete(false);
                        }}
                        title="Delete Service"
                        onToggleConfirm={() => {
                            setShowConfirmDelete(false);
                        }}
                        openDialog={showConfirmDelete}
                        okText="Delete"
                        noText="Cancel"
                        messageText={'Are you sure you want to delete this service?'}
                    />
                    <Confirm
                        onAction={() => {
                            setShowAddError(false);
                        }}
                        title="Error adding preferred vendor"
                        onToggleConfirm={() => {
                            setShowAddError(false);
                        }}
                        openDialog={showAddError}
                        okText="Ok"
                        hideCancel
                        noText=""
                        messageText="There's already an existing record with the same service and states."
                    />
                </>
            )}
        </>
    );
};
