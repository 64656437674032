import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from "./DxHeader.Styles";
import { Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import SpecificBodyPartEntity from '../../../../../../entities/SpecificBodyPart.Entity';
import InjuryTypeEntity from '../../../../../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../../../../../entities/BodySide.Entity';
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";
import DiagnosticProcedureDetailDataModel from '../../../../../../data-models/DiagnosticProcedureDetail.DataModel';
import DiagnosticProcedureEntity from '../../../../../../entities/DiagnosticProcedure.Entity';
import { MaterialTableProps } from 'material-table';
import MaterialTable from "../../../../../generic-components/material-table/MaterialTable.Component";
import DiagnosticProcedureTableDataModel from '../../../../../../data-models/DiagnosticProcedureTable.DataModel';
import DiagnosisDataModel from '../../../../../../data-models/Diagnosis.DataModel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ServiceTypeEntity from '../../../../../../entities/ServiceType.Entity';
import LanguageEntity from '../../../../../../entities/Language.Entity';
import ServiceTypeRequestDxEntity from '../../../../../../entities/ServiceTypeRequestDx.Entity';
import { useValidationDialog } from '../../../../../../support/custom-hooks/useValidationDialog';
import { IDiagnosticServiceReferralFormErrors } from '../DiagnosticServiceReferralForm.Component';
import { ExtraDxFields } from '../extra-dx-fields/ExtraDxFields.Component';
import { toast } from 'react-toastify';

interface IDxHeaderProps {
    referralId: number,
    specificBodyParts: Array<SpecificBodyPartEntity>,
    injuryTypes: Array<InjuryTypeEntity>,
    bodySides: Array<BodySideEntity>,
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>,
    handleServiceTypesEdit: any,
    errors: IDiagnosticServiceReferralFormErrors,
    validate: (isChange?: boolean, isSubmit?: boolean) => void,
    handleSetIsValidDxProcedureFunction: (currentPageIsValidDxProcedureFunction: () => boolean) => void;
    hasBeenSubmitted: boolean,
    serviceTypes: Array<ServiceTypeEntity>,
    searchDiagnosisByCodeDescription: (description: string) => void,
    diagnosisFindedArray: Array<DiagnosisDataModel>,
    procedures: Array<DiagnosticProcedureEntity>,
    proceduresTable: Array<DiagnosticProcedureTableDataModel>;
    getDxSpecificBodyParts: (serviceTypeId: number) => void,
    getDxProcedureDetailsByServiceTypeId: (serviceTypeId: number) => void,
    languages: Array<LanguageEntity>,
    handleServiceTypeRequestDxEdit: any,
    serviceTypeRequestDx: ServiceTypeRequestDxEntity;
    loadingDiagnosis:boolean;
}

export interface IDxHeaderErrors {
    serviceTypeNumber?: string,
    diagnosisId?: string,
    diagnosticProcedureDetailId?: string
}

export const DxHeader: FunctionComponent<IDxHeaderProps> = (props: IDxHeaderProps) => {
    const classes = styles();
    const abortController = new AbortController();
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);

    const [serviceTypeRequestDx, setServiceTypeRequestDx] = React.useState<ServiceTypeRequestDxEntity>(props.serviceTypeRequestDx ? props.serviceTypeRequestDx : new ServiceTypeRequestDxEntity());

    const [counterState2, setCounterState2] = React.useState(null);
    const [counterState, setCounterState] = React.useState(props.handleServiceTypeRequestDxEdit ? props.handleServiceTypeRequestDxEdit : null);

    const [showAgeOfInjury, setShowAgeOfInjury] = React.useState(false);

    const [keyDiagnosis, setKeyDiagnosis] = React.useState(0);
    const [keySpecificBodyPart, setKeySpecificBodyPart] = React.useState(0);

    const [showCheckBoxAgeOfInjury, setShowCheckBoxAgeOfInjury] = React.useState(false);

    const [procedureToAdd, setProcedureToAdd] = React.useState<DiagnosticProcedureEntity>(null);

    const [listOfProcedures, setListOfProcedures] = React.useState<Array<DiagnosticProcedureEntity>>(new Array<DiagnosticProcedureEntity>());
    
    const [rowsUpdated, setRowsUpdated] = React.useState<Array<DiagnosticProcedureTableDataModel>>(null);

    const [languages, setLanguages] = React.useState<Array<LanguageEntity>>(null);
    const [diagnosisArray, setDiagnosisArray] = React.useState<Array<DiagnosisDataModel>>(props.diagnosisFindedArray);
    const [localServiceTypes, setLocalServiceTypes] = React.useState<Array<ServiceTypeEntity>>(null);
    const [diagnosisSearched, setDiagnosisSearched] = React.useState<string>("");
    const [diagnosisSelected, setDiagnosisSelected] = React.useState<DiagnosisDataModel>(null);
    const [diagnosisNoOptionText, setDiagnosisNoOptionText] = React.useState<string>("Type at least 3 characters");
    const [diagnosisText, setDiagnosisText] = React.useState<string>("");

    const [specificBodyPartSelected, setspecificBodyPartSelected] = React.useState<SpecificBodyPartEntity>(null);
    const [errors, setErrors] = React.useState<IDxHeaderErrors>(null);
    const {Dialog:DialogForProcedures, checkForErrors:checkForErrorsForProcedures} = useValidationDialog("Validation","In order to continue, one option of each service type must be added.","Ok");

    //componentDidMount
    useEffect(() => {
        props.handleSetIsValidDxProcedureFunction(isValidDxProcedure);
        if(!procedureToAdd){
            let newP : DiagnosticProcedureEntity = new DiagnosticProcedureEntity();
            // newP.ageOfInjuryReview = false;
            setProcedureToAdd(newP)
        }

        if(props.serviceTypeRequestDx){
            let newDPAD: ServiceTypeRequestDxEntity = props.serviceTypeRequestDx;
            setServiceTypeRequestDx(newDPAD);
            setCounterState(newDPAD);
        } else {
            let newDPAD: ServiceTypeRequestDxEntity = new ServiceTypeRequestDxEntity();
            newDPAD.referralId = props.referralId;
            setServiceTypeRequestDx(newDPAD);
            setCounterState(newDPAD);
        }
        
        if(props.procedures){
            setListOfProcedures(props.procedures);
        }

        if(!localServiceTypes && props.serviceTypes){
            setLocalServiceTypes(props.serviceTypes);
        }

        setKeyDiagnosis(0);
        setKeySpecificBodyPart(0);

        setDiagnosisArray(props.diagnosisFindedArray);

        var sortedLanguages: Array<LanguageEntity> = [...props.languages, { languageId: 0, languageName: 'Other' }];
        sortedLanguages.sort((a, b) => a.languageName.localeCompare(b.languageName));
        sortedLanguages.unshift(sortedLanguages.splice(12, 1)[0]);
        setLanguages(sortedLanguages);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    useEffect(() => {
        if(!!props.proceduresTable){
            setRowsUpdated(props.proceduresTable);
        }

        return function cleanup() {
            abortController.abort();
        }
    }, [props.proceduresTable]);
        
    const isValidDxProcedure = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IDxHeaderErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListDxProcedure();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListDxProcedure = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IDxHeaderErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }
        
        if (!(props.procedures?.length > 0 )) {
            errors.serviceTypeNumber = 'Required';

            if (!( procedureToAdd?.serviceTypeNumber > 0 ))
                errors.serviceTypeNumber = 'Required';
            else {
                if ( (props.procedureDetails?.length > 0) && !( procedureToAdd?.diagnosticProcedureDetailId > 0 )) errors.diagnosticProcedureDetailId = "Required";
                if ( !( procedureToAdd?.diagnosisId > 0 ) ) errors.diagnosisId = "Required";
            }
        }

        return errors;
    }
    const getErrorsListDxProcedureLocal = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IDxHeaderErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if (!( procedureToAdd?.serviceTypeNumber > 0 ))
            errors.serviceTypeNumber = 'Required';
        else {
            if ( (props.procedureDetails?.length > 0) && !( procedureToAdd?.diagnosticProcedureDetailId > 0 )) errors.diagnosticProcedureDetailId = "Required";
            if ( !( procedureToAdd?.diagnosisId > 0 ) ) errors.diagnosisId = "Required";
        }
        return errors;
    }

    const localValidate = (isChange?: boolean) => {
        if(!hasBeenSubmitted){
            setHasBeenSubmitted(true);
        }

        let eerrors: IDxHeaderErrors = {};

        eerrors.serviceTypeNumber = procedureToAdd && procedureToAdd.serviceTypeNumber > 0 ? "" : "Required";
        
        if(procedureToAdd?.serviceTypeNumber > 0)
        {
            eerrors.diagnosisId = procedureToAdd && procedureToAdd.diagnosisId > 0 ? "" : "Required";
        }
        setErrors(eerrors);
        return checkForErrorsForProcedures(eerrors, isChange);
    }
    
    const handleDiagnosisSelected = (event,value) => {
        event.persist();
        let newP: DiagnosticProcedureEntity;
        if(!procedureToAdd){
            newP = new DiagnosticProcedureEntity();
        } else {
            newP = procedureToAdd;
        }
        
        setDiagnosisSelected(value)

        newP.diagnosisId = value?.diagnosisId;
        
        setProcedureToAdd(newP);
        
        if(hasBeenSubmitted)
        localValidate(true);
    }

    const handleSpecificBodyPartSelected = (event,value) => {
        event.persist();
        
        let newP: DiagnosticProcedureEntity;
        if(!procedureToAdd){
            newP = new DiagnosticProcedureEntity();
        } else {
            newP = procedureToAdd;
        }

        setspecificBodyPartSelected(value)

        newP.specificBodyPartId = value?.specificBodyPartId;
        
        setProcedureToAdd(newP);

        if(hasBeenSubmitted)
            localValidate(true);
    }
    
    const handleTextfieldChangeDiagnosis = (event) => {  
        event.persist();
        setDiagnosisText(event.target.value);
        if(event.target.value.length > 2){
            props.searchDiagnosisByCodeDescription(event.target.value);
        } else if (event.target.value.length > 2 && props.diagnosisFindedArray.length == 0){
            setDiagnosisNoOptionText("No results found");
        } else {
            setDiagnosisNoOptionText("Type at least 3 characters");
        }
        let newST: DiagnosticProcedureEntity = procedureToAdd;
        setProcedureToAdd(newST);

        if(hasBeenSubmitted)
            localValidate(true);
    }

    useEffect(() => {
        if(props.loadingDiagnosis == true){
            setDiagnosisNoOptionText("Loading...");
        } else if (diagnosisText.length > 2 && props.diagnosisFindedArray.length == 0) {
            setDiagnosisNoOptionText("No results found");
        } else if (diagnosisText.length < 3) {
            setDiagnosisNoOptionText("Type at least 3 characters");
        }

        return function cleanup() {
            abortController.abort();
        }
    }, [props.loadingDiagnosis]);
    

    const handleTextFieldChangeDxHeader = (event) => {
        event.persist();
        
        if (event.target.name == "serviceTypeNumber" || event.target.id == "serviceTypeNumber") {
            cleanTextFields();
            setHasBeenSubmitted(false)
            let newP : DiagnosticProcedureEntity = new DiagnosticProcedureEntity();
            
            if (!newP.referralId) {
                newP.referralId = props.referralId;
            }
            if (event.target.type == 'checkbox') {
                setCounterState2({...counterState2,[event.target.id ? event.target.id : event.target.name] : event.target.checked});
                newP[event.target.id ? event.target.id : event.target.name] = event.target.checked;
            }
            else {
                setCounterState2({...counterState2,[event.target.id ? event.target.id : event.target.name] : event.target.value});
                newP[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value?.toString() == "true" ? true : event.target.value?.toString() == "false" ? false : event.target.value;
            }
            
            if((localServiceTypes.findIndex(st => (st.serviceTypeAbbreviation == "X-RAY" || st.serviceTypeAbbreviation == "MRI") && (event.target.value == st.serviceTypeId)) != -1) && !!newP?.serviceTypeNumber && newP.serviceTypeNumber != 0){
                setShowCheckBoxAgeOfInjury(true);
                setShowAgeOfInjury(true);
            }
            else {
                newP.ageOfInjuryReview = null;
                setShowCheckBoxAgeOfInjury(false);
                setShowAgeOfInjury(false);
            }
            setProcedureToAdd(newP);

            let eerrors: IDxHeaderErrors = {};
            setErrors(eerrors);
        }
        else {
            let newDPAD : DiagnosticProcedureEntity = procedureToAdd;
            if (!newDPAD.referralId) {
                newDPAD.referralId = props.referralId;
            }
            if (event.target.type == 'checkbox') {
                setCounterState2({...counterState2,[event.target.id ? event.target.id : event.target.name] : event.target.checked});
                newDPAD[event.target.id ? event.target.id : event.target.name] = event.target.checked;
            }
            else {
                setCounterState2({...counterState2,[event.target.id ? event.target.id : event.target.name] : event.target.value});
                newDPAD[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value?.toString() == "true" ? true : event.target.value?.toString() == "false" ? false : event.target.value;
            }
            setProcedureToAdd(newDPAD);

            if(hasBeenSubmitted)
                localValidate(true);
        }

        setDiagnosisArray(props.diagnosisFindedArray)
        
    };

    const formatRow = (newProcedure: DiagnosticProcedureEntity, diagnosis: DiagnosisDataModel, specificBodyPart: SpecificBodyPartEntity):DiagnosticProcedureTableDataModel => {

        let newRow: DiagnosticProcedureTableDataModel = new DiagnosticProcedureTableDataModel;

        newProcedure.referralId = props.referralId;

        for(let sbp of props.specificBodyParts){
            if(sbp.specificBodyPartId == specificBodyPart?.specificBodyPartId){
                newRow.specificBodyPartId = sbp?.specificBodyPartId;
                newRow.specificBodyPartName = sbp?.specificBodyPartName;
                break;
            }
        }
        for(let bs of props.bodySides){
            if(bs.bodySideId == newProcedure.bodySideId){
                newRow.bodySideId = bs?.bodySideId;
                newRow.bodySideName = bs?.bodySideName;
                break;
            }
        }
        for(let st of props.serviceTypes){
            if(st.serviceTypeId == newProcedure.serviceTypeNumber){
                newRow.serviceTypeId = st?.serviceTypeId;
                newRow.serviceTypeName = st?.serviceTypeName;
                break;
            }
        }
        for(let pd of props.procedureDetails){
            if(pd.diagnosticProcedureDetailId == newProcedure.diagnosticProcedureDetailId){
                newRow.procedureDetailsId = pd?.diagnosticProcedureDetailId;
                newRow.procedureDetailsName = pd?.diagnosticProcedureDetailName;
                break;
            }
        }
        if(!!diagnosis?.diagnosisId){
            newRow.diagnosisId = diagnosis?.diagnosisId
            newRow.diagnosisName = diagnosis?.diagnosisCodeNumber?.toString() + ' - ' + diagnosis?.diagnosisCodeDescription;
        }

        newRow.ageOfInjuryReview = newProcedure?.ageOfInjuryReview ? true : false;

        return newRow;
    };

    const handleButtonAddProcedure = (event) => {
        event.persist();
        if(localValidate(false)){
            let newRowsTable: Array<DiagnosticProcedureTableDataModel>;
            if (!rowsUpdated) {
                newRowsTable = new Array<DiagnosticProcedureTableDataModel>();
            } else {
                newRowsTable = rowsUpdated;
            }
    
            let newListProcedures: Array<DiagnosticProcedureEntity> = listOfProcedures;
            let newProcedure: DiagnosticProcedureEntity = procedureToAdd;
    
            let newRow = formatRow(procedureToAdd,diagnosisSelected,specificBodyPartSelected);
    
            newProcedure.referralId = props.referralId;
    
            newRowsTable.push(newRow);
            setRowsUpdated(newRowsTable);
    
            newListProcedures.push(newProcedure);
            setListOfProcedures(newListProcedures);
    
            props.handleServiceTypesEdit(listOfProcedures,newRowsTable);
    
            cleanTextFields();
        }

        if(hasBeenSubmitted)
            localValidate(true);
    }

    const cleanTextFields = () => {
        let newP : DiagnosticProcedureEntity = new DiagnosticProcedureEntity();
        let newD : DiagnosisDataModel = new DiagnosisDataModel();
        let newS : SpecificBodyPartEntity = new SpecificBodyPartEntity();
        setCounterState2(newP);
        setProcedureToAdd(newP);
        setDiagnosisSelected(newD);
        setDiagnosisSearched("");
        props.searchDiagnosisByCodeDescription("");
        setKeyDiagnosis(1);
        setKeySpecificBodyPart(1);
        setspecificBodyPartSelected(newS);
        setShowCheckBoxAgeOfInjury(false);
        setShowAgeOfInjury(false);
        setDiagnosisNoOptionText("Type at least 3 characters");
    }

    const handleDeleteRow = (rowData : DiagnosticProcedureTableDataModel) => {

        let oldTable:Array<DiagnosticProcedureTableDataModel> = rowsUpdated;
        let newTable: Array<DiagnosticProcedureTableDataModel>;
        newTable = oldTable.filter(r => r.serviceTypeId != rowData.serviceTypeId || 
                                        r.specificBodyPartId != rowData.specificBodyPartId || 
                                        r.bodySideId != rowData.bodySideId || 
                                        r.procedureDetailsId != rowData.procedureDetailsId || 
                                        r.diagnosisId != rowData.diagnosisId ||
                                        r.ageOfInjuryReview != rowData.ageOfInjuryReview
                                    );
        setRowsUpdated(newTable);

        let oldListOfProcedures: Array<DiagnosticProcedureEntity> = listOfProcedures;                                             
        let newListOfProcedures: Array<DiagnosticProcedureEntity>;

        newListOfProcedures = oldListOfProcedures.filter(r  =>  r.serviceTypeNumber != rowData.serviceTypeId || 
                                                                r.specificBodyPartId != rowData.specificBodyPartId || 
                                                                r.bodySideId != rowData.bodySideId || 
                                                                r.diagnosticProcedureDetailId != rowData.procedureDetailsId || 
                                                                r.diagnosisId != rowData.diagnosisId || 
                                                                    (!((r.ageOfInjuryReview == null && rowData.ageOfInjuryReview == false) ||
                                                                    (r.ageOfInjuryReview == false && rowData.ageOfInjuryReview == null) ||
                                                                    (r.ageOfInjuryReview == rowData.ageOfInjuryReview)))
                                                                
                                                        );   
        setListOfProcedures(newListOfProcedures);                                              
        props.handleServiceTypesEdit(newListOfProcedures);
    };

    const clientsTableProps: MaterialTableProps<DiagnosticProcedureTableDataModel> = {
        columns: [
            {
                title: 'Service Type',
                field: 'serviceTypeName',
                render: rowData => rowData?.serviceTypeName
            },
            {
                title: 'Specific Body Part',
                field: 'specificBodyPartName',
                render: rowData => rowData?.specificBodyPartName
            },
            {
                title: 'Body Side',
                field: 'bodySideName',
                render: rowData => rowData?.bodySideName
            },
            {
                title: 'Diagnosis',
                field: 'diagnosisName',
                render: rowData => rowData?.diagnosisName
            },
            {
                title: 'Procedure Details',
                field: 'procedureDetailsName',
                render: rowData => rowData?.procedureDetailsName
            },
            {
                title: 'Age Of Injury',
                field: 'ageOfInjuryReview',
                render: rowData => rowData?.ageOfInjuryReview ? "Yes" : "No"
            },
        ],
        data: rowsUpdated ? rowsUpdated : [],
        options: {
            filtering: false,
            showTitle: true,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
        style:{
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions:[
            {
                icon:'delete',
                tooltip:'delete detail',
                position:'row',
                onClick: (event, rowData) => {
                    if(Array.isArray(rowData)) return;
                    handleDeleteRow(rowData);
                }
            },
          ],
        components:{
            Action: props => {
                switch(props.action.position){
                    case 'row':
                        return (
                            <IconButton
                                {...props.action} 
                                onClick={(event) => props.action.onClick(event, props.data)}
                            >
                                {props.action.icon === 'delete' && <DeleteIcon />}
                            </IconButton>
                        )
                }
            }
        },
    }

    const handleServiceTypeRequestDx = (event) => {
        event.persist();

        let newDPAD : ServiceTypeRequestDxEntity = serviceTypeRequestDx;

        if (!newDPAD.referralId) {
            newDPAD.referralId = props.referralId;
        }

        if (event.target.type == 'checkbox') {
            setCounterState({...counterState,[event.target.id ? event.target.id : event.target.name] : event.target.checked});
            newDPAD[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        }
        else {
            setCounterState({...counterState,[event.target.id ? event.target.id : event.target.name] : event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value.toString() == "true" ? true : event.target.value.toString() == "false" ? false : event.target.value});
            newDPAD[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value?.toString() == "true" ? true : event.target.value?.toString() == "false" ? false : event.target.value;
        }

        if(newDPAD.languageId !== 0) {
            newDPAD.otherLanguage = null;
        }
        
        if (!newDPAD.translation) {
            newDPAD.otherLanguage = null;
            newDPAD.languageId = null;
        }

        setServiceTypeRequestDx(newDPAD);

        props.handleServiceTypeRequestDxEdit(newDPAD)

        if(props.hasBeenSubmitted)
            props.validate(true, false);
    }

    React.useEffect(() => {
        props.getDxSpecificBodyParts(procedureToAdd?.serviceTypeNumber);
        !!procedureToAdd?.serviceTypeNumber && props.getDxProcedureDetailsByServiceTypeId(procedureToAdd?.serviceTypeNumber);
    }, [procedureToAdd?.serviceTypeNumber])


    const handlePaste = (event) => {
        event.clipboardData.items[0].getAsString(text => {
            console.log(text.length);
          if(text.length>1000)
          {
              toast.warning('⚠️ ' + 'You’ve reached the character limit, please shorten your message');
          }
        })
      }


    return (
        <Fragment>
            {
                localServiceTypes ?
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={2}>
                            <TooltipTextField
                                type='select'
                                formControlClass={classes.selectInput}
                                inputLabelId="demo-simple-select-label"
                                id="serviceTypeNumber"
                                name="serviceTypeNumber"
                                label='Service Type'
                                key={procedureToAdd?.serviceTypeNumber}
                                defaultValue={procedureToAdd?.serviceTypeNumber}
                                onChange={(event) => handleTextFieldChangeDxHeader(event)}
                                required 
                                error={!!errors?.serviceTypeNumber} 
                                helperText={errors?.serviceTypeNumber}
                            >
                                {
                                    localServiceTypes?.map((st, index) => {
                                        return (
                                            <MenuItem key={st.serviceTypeId} value={st.serviceTypeId}>{st.serviceTypeName}</MenuItem>
                                        )
                                    })
                                }
                            </TooltipTextField>
                        </Grid>
                        <Grid item xs={2}>
                            <Autocomplete
                                id="specificBodyPartId"
                                options={props.specificBodyParts}
                                getOptionLabel={option => option.specificBodyPartName }
                                key={keySpecificBodyPart}
                                onChange={(event, value) => handleSpecificBodyPartSelected(event, value)}
                                disabled={props.specificBodyParts?.length === 0}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Specific Body Parts"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TooltipTextField
                                type='select'
                                label='Body Side'
                                formControlClass={classes.selectInput}
                                inputLabelId="demo-simple-select-label"
                                id="bodySideId"
                                name="bodySideId"
                                key={procedureToAdd?.bodySideId}
                                value={procedureToAdd?.bodySideId === null ? undefined : procedureToAdd?.bodySideId}
                                onChange={(event) => handleTextFieldChangeDxHeader(event)}
                                disabled={specificBodyPartSelected?.specificBodyPartId > 0 ? false : true}
                                notShrinkOnDisabled
                            >
                                {
                                    props.bodySides?.map((bodySide, index) => {
                                        return (
                                            <MenuItem key={bodySide?.bodySideId} value={bodySide?.bodySideId}>{bodySide?.bodySideName}</MenuItem>
                                        )
                                    })
                                }
                            </TooltipTextField>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id="diagnosisId"
                                options={diagnosisArray}
                                getOptionLabel={option => option.diagnosisCodeNumber + " - " + option.diagnosisCodeDescription }
                                key={keyDiagnosis}
                                onChange={(event, value) => handleDiagnosisSelected(event, value)}
                                noOptionsText={diagnosisNoOptionText}
                                disabled={!procedureToAdd?.serviceTypeNumber}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Diagnosis"
                                        fullWidth
                                        value={diagnosisSearched}
                                        onChange={handleTextfieldChangeDiagnosis}
                                        required={!!procedureToAdd?.serviceTypeNumber}
                                        error={!!errors?.diagnosisId} 
                                        helperText={errors?.diagnosisId}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <React.Fragment>
                                                    {props.loadingDiagnosis && <CircularProgress color="inherit" size={20} />}
                                                    {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                          }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TooltipTextField
                                type='select'
                                label='Procedure details'
                                formControlClass={classes.selectInput}
                                inputLabelId="demo-simple-select-label"
                                id="diagnosticProcedureDetailId"
                                name="diagnosticProcedureDetailId"
                                key={procedureToAdd?.diagnosticProcedureDetailId}
                                value={procedureToAdd?.diagnosticProcedureDetailId}
                                onChange={(event) => handleTextFieldChangeDxHeader(event)}
                                disabled={!procedureToAdd?.serviceTypeNumber ||  props.procedureDetails?.length === 0}
                                notShrinkOnDisabled                                
                                helperText={errors?.diagnosticProcedureDetailId}
                            >
                                {
                                    props.procedureDetails?.map((procedureDetail, index) => {
                                        return (
                                            <MenuItem key={procedureDetail?.diagnosticProcedureDetailId} value={procedureDetail?.diagnosticProcedureDetailId}>{procedureDetail?.diagnosticProcedureDetailName}</MenuItem>
                                        )
                                    })
                                }
                            </TooltipTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                key={procedureToAdd?.ageOfInjuryReview ? "true" : "false"}
                                control={
                                    <Checkbox
                                        id="ageOfInjuryReview"
                                        name="ageOfInjuryReview"
                                        disableRipple
                                        checked={procedureToAdd?.ageOfInjuryReview}
                                        onChange={(event) => handleTextFieldChangeDxHeader(event)}
                                        disabled={!showCheckBoxAgeOfInjury}
                                    />
                                }
                                label={<Typography variant="body2">Age Of Injury Review</Typography>}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={2}>
                            <Button className={classes.button} onClick={handleButtonAddProcedure} disabled={false} variant="contained" color="primary" >ADD</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialTable tableProps={clientsTableProps} />
                        </Grid>
                        <ExtraDxFields
                            serviceTypeRequestDx={props.serviceTypeRequestDx}
                            errors={props.errors}
                            validate={props.validate}
                            handleServiceTypeRequestDxEdit={props.handleServiceTypeRequestDxEdit}
                            hasBeenSubmitted={props.hasBeenSubmitted}
                            referralId={props.referralId}
                        />
                        <Grid item xs={12}>
                            <Divider className={classes.divider}></Divider>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key={serviceTypeRequestDx?.rushRequest == null ? "false" : "true"}
                                        id="rushRequest"
                                        name="rushRequest"
                                        disableRipple
                                        checked={serviceTypeRequestDx?.rushRequest}
                                        onClick={(event) => handleServiceTypeRequestDx(event)}
                                    />
                                }
                                label={<Typography variant="body2">Rush Request</Typography>}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Patient will be using transportation</FormLabel>
                                <RadioGroup row aria-label="position" id="transportation" name="transportation" key={serviceTypeRequestDx?.transportation ? "true" : "false"} onChange={(event) => handleServiceTypeRequestDx(event)} value={serviceTypeRequestDx?.transportation ? true : false}>
                                    <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label="Patient Language" 
                                id="patientLanguage" 
                                name="patientLanguage" 
                                onChange={(event) => handleServiceTypeRequestDx(event)} 
                                defaultValue={serviceTypeRequestDx?.patientLanguage ? serviceTypeRequestDx?.patientLanguage : ''} 
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Patient will be using a translator</FormLabel>
                                <RadioGroup row aria-label="position" id="translation" name="translation" key={serviceTypeRequestDx?.translation ? "true" : "false"} onChange={(event) => handleServiceTypeRequestDx(event)} value={serviceTypeRequestDx?.translation ? true : false}>
                                    <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} container spacing={3}>
                            <Grid item xs={12}>
                                <TooltipTextField
                                    type='select'
                                    formControlClass={classes.selectInput}
                                    label='Language'
                                    inputLabelId="demo-simple-select-label"
                                    id="languageId"
                                    name="languageId"
                                    key={serviceTypeRequestDx?.languageId}
                                    value={serviceTypeRequestDx?.languageId || serviceTypeRequestDx?.languageId === 0 ? serviceTypeRequestDx?.languageId : ''}
                                    onChange={(event) => handleServiceTypeRequestDx(event)}
                                    disabled={!serviceTypeRequestDx?.translation}
                                    zeroIsValid
                                >
                                    {
                                        languages.map((language, index) => {
                                            return (
                                                <MenuItem key={language?.languageId} value={language?.languageId}>{language?.languageName}</MenuItem>
                                            )
                                        })
                                    }
                                </TooltipTextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label="Other Language" 
                                id="otherLanguage" 
                                disabled={!!serviceTypeRequestDx?.languageId || !serviceTypeRequestDx?.translation || serviceTypeRequestDx?.languageId !== 0} 
                                onChange={(event) => handleServiceTypeRequestDx(event)} value={serviceTypeRequestDx?.otherLanguage ? serviceTypeRequestDx?.otherLanguage : ''} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.selectInput}>
                                <TooltipTextField 
                                    maxRows={4} 
                                    multiline={true} 
                                    label="Additional Comments" 
                                    id="otherComments" 
                                    name="otherComments" 
                                    onChange={(event) => handleServiceTypeRequestDx(event)} 
                                    defaultValue={serviceTypeRequestDx?.otherComments ? serviceTypeRequestDx?.otherComments : ''} 
                                    inputProps={{ maxLength: 1000 }} 
                                    helperText={`${counterState?.otherComments ? counterState?.otherComments.length : 0 }/${1000}`} 
                                    onPaste={handlePaste}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : <EmptyDataLabel></EmptyDataLabel>
            }
            {DialogForProcedures}
        </Fragment>
    );
}