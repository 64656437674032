import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from './ImeReferralForm.Styles';
import {
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    MenuItem,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Input,
    Checkbox,
    ListItemText,
    MenuProps,
    TextField 
} from '@material-ui/core';

import InputMask from 'react-input-mask';
import SpecialtyEntity from '../../../../../entities/Specialty.Entity';
import LanguageEntity from '../../../../../entities/Language.Entity';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import ImeEntity from '../../../../../entities/Ime.Entity';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReferralContactTypesEnum } from '../../../../../support/enums/ReferralContactTypesEnum';
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ImeCoverLetter } from './ime-cover-letter/ImeCoverLetter.Component';
import IMECoverLetterEntity from '../../../../../entities/IMECoverLetter.Entity';
import { TooltipTextField } from '../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import { useValidationDialog } from '../../../../../support/custom-hooks/useValidationDialog';
import { ClearSelect } from '../../../../generic-components/clear-select/ClearSelect.Component';
import { isNullOrEmpty } from '../../../../../support/common';

import AddressAutocompleteEntity,{MakeFullAddress} from '../../../../../entities/ReferralAddress.Entity';
import CloseIcon from '@material-ui/icons/Close';
import SearchAddressAsync, { AddressValue } from '../../../../../components/generic-components/search-autocomplete/SearchAddressAsync'
import { toast } from 'react-toastify';

/* eslint-disable no-use-before-define */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
  },
  input: {
    width: 200,
  },
  listbox: {
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  },
}));


interface IImeReferralFormProps {
    referralId: number;
    specialties: Array<SpecialtyEntity>;
    languages: Array<LanguageEntity>;
    bodyParts: Array<SpecificBodyPartEntity>;
    states: Array<StateEntity>;
    ime: ImeEntity;
    specificBodyParts: Array<number>;
    handleImeEdit: (edit: ImeEntity) => void;
    treatingPhysician: ReferralContactEntity;
    handleTreatingPhysicianEdit: any;
    optionalImePhysician: ReferralContactEntity;
    handleOptionalImePhysicianEdit: any;
    handleImeBodyPartsEdit: any;
    handleImeCoverLetterEdit: (edit: IMECoverLetterEntity) => void;
    coverLetterState: IMECoverLetterEntity;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    handleSetIsValidImeFunction: (currentPageIsValidImeFunction: () => boolean, serviceTypeString: string) => void;
    
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;

    handleUpdateIme: (currentPageValidationFunction: () => boolean) => void;
    handleUpdateTreatingPhysician: (currentPageValidationFunction: () => boolean) => void;
    handleUpdateOptionalImePhysician: (currentPageValidationFunction: () => boolean) => void;
    handleUpdateImeCoverLetter: () => void;
}

export interface IImeReferralFormErrors {
    specialtyOther?: string;
    optionalPhysicianFirstName?: string;
    optionalPhysicianLastName?: string;
    optionalPhysicianAddressLine1?: string;
    //optionalPhysicianZipCode?: string;
    optionalPhysicianCityName?: string;
    optionalPhysicianState?: string;
    optionalPhysicianPrimaryPhoneNumber?: string;
    //optionalPhysicianEmail?: string;

    treatingPhysicianFirstName?: string;
    treatingPhysicianLastName?: string;
    treatingPhysicianAddressLine1?: string;
    treatingPhysicianZipCode?: string;
    treatingPhysicianCityName?: string;
    treatingPhysicianState?: string;
    //treatingPhysicianPrimaryPhoneNumber?: string;
    treatingPhysicianEmail?: string;
}

export const ImeReferralForm: FunctionComponent<IImeReferralFormProps> = (props: IImeReferralFormProps) => {
    const classes = styles();
    const abortController = new AbortController();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps: Partial<MenuProps> = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [errors, setErrors] = React.useState<IImeReferralFormErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');

    const specialties = [...props.specialties?? [], { specialtyId: 0, specialtyName: 'Other' }];
    const languages = [...props.languages?? [], { languageId: 0, languageName: 'Other' }];
    const specificBodyParts = props.bodyParts?.filter((x) => x.specificBodyPartId != null);

    const [referralImeState, setReferralImeState] = React.useState(null);
    const [referralImeBodyPartsState, setReferralImeBodyPartsState] = React.useState(null);
    const [referralTreatingPhysicianState, setreferralTreatingPhysicianState] = React.useState(null);
    const [referralOptionalImePhysician, setreferralOptionalImePhysician] = React.useState(null);
    
    const [referralTreatingPhysicianFullAddress, setReferralTreatingPhysicianFullAddress] = React.useState("");
    const [referralImePhysicianFullAddress, setReferralImePhysicianFullAddress] = React.useState("");

    const passAddress = (address:AddressAutocompleteEntity) => {
        let newTreatingPhysician: ReferralContactEntity = referralTreatingPhysicianState;
        newTreatingPhysician["cityName"] = address.city;
        newTreatingPhysician["addressLine1"] = `${address.streetNumber? address.streetNumber:''} ${address.streetName}`;
        newTreatingPhysician["addressLine2"] = address.address2 ?? "";
        newTreatingPhysician["zipCode"] = address.zipCode;        

        const stateItem = props.states.find(element => element.stateName==address.stateName);
        newTreatingPhysician["stateId"] = stateItem?.stateId;

        props.handleTreatingPhysicianEdit(newTreatingPhysician);
        setreferralTreatingPhysicianState(newTreatingPhysician);
        setReferralTreatingPhysicianFullAddress(address.fullAddress);
        
      }

      const passAddressIME = (address:AddressAutocompleteEntity) => {
        let newImePhysician: ReferralContactEntity = referralOptionalImePhysician;
        newImePhysician["cityName"] = address.city;
        newImePhysician["addressLine1"] = `${address.streetNumber? address.streetNumber:''} ${address.streetName}`;
        newImePhysician["addressLine2"] = address.address2 ?? "";
        newImePhysician["zipCode"] = address.zipCode;
        

        const stateItem = props.states.find(element => element.stateName==address.stateName);
        newImePhysician["stateId"] = stateItem?.stateId;

        props.handleOptionalImePhysicianEdit(newImePhysician);
        setreferralOptionalImePhysician(newImePhysician);
        setReferralImePhysicianFullAddress(address.fullAddress);        
      }

    //functional component states

    //componentDidMount
    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty("Ime_box" ) )  props.handleOpenCloseStatus("Ime_box", true);
        if ( !props.openCloseStatus.hasOwnProperty("ImeTreatingPhysician_box" ) ) props.handleOpenCloseStatus("ImeTreatingPhysician_box", true);
        if ( !props.openCloseStatus.hasOwnProperty("OptionalImePhysician_box" ) )  props.handleOpenCloseStatus("OptionalImePhysician_box", true);
        if ( !props.openCloseStatus.hasOwnProperty("CoverLetter_box" ) ) props.handleOpenCloseStatus("CoverLetter_box", true); 
    },[]);
    
    useEffect(() => {
        if (!props.ime) {
            props.handleImeEdit({ referralId: props.referralId } as ImeEntity);
        }
        if (
            props.ime &&
            (props.ime.isCoveredLetterRequired == null || props.ime.isCoveredLetterRequired == undefined)
        ) {
            let newIme = props.ime;
            newIme.isCoveredLetterRequired = true;
            props.handleImeEdit(newIme);
        }
        setReferralImeState(props.ime);
        setReferralImeBodyPartsState(props.specificBodyParts ? props.specificBodyParts : []);
        setreferralTreatingPhysicianState(props.treatingPhysician ? props.treatingPhysician : {});
        setreferralOptionalImePhysician(props.optionalImePhysician ? props.optionalImePhysician : {});

        props.handleSetIsValidImeFunction(isValidIME, "IME");

        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    useEffect(() => {
        if(referralTreatingPhysicianState?.stateId)
        {
            const stateItem = props.states?.find(element => element.stateId==referralTreatingPhysicianState.stateId);
            
            setReferralTreatingPhysicianFullAddress(MakeFullAddress(referralTreatingPhysicianState,stateItem.stateName));
        }
    }, [referralTreatingPhysicianState]);

    useEffect(() => {
        if(referralOptionalImePhysician?.stateId)
        {
            const stateItem = props.states?.find(element => element.stateId==referralOptionalImePhysician.stateId);
            
            setReferralImePhysicianFullAddress(MakeFullAddress(referralOptionalImePhysician,stateItem.stateName));
        }
    }, [referralTreatingPhysicianState]);

    const handleClear = ()=>{
        
        let newTreatingPhysician: ReferralContactEntity = referralTreatingPhysicianState
        newTreatingPhysician["cityName"] = undefined
        newTreatingPhysician["addressLine1"] = undefined
        newTreatingPhysician["addressLine2"] = undefined
        newTreatingPhysician["zipCode"] = undefined
        
        newTreatingPhysician["stateId"] = undefined;
        
        setReferralTreatingPhysicianFullAddress("");

        props.handleTreatingPhysicianEdit(newTreatingPhysician);
        setreferralTreatingPhysicianState(newTreatingPhysician);
    }
    const handleClearIME = () => { 
        let newImePhysician: ReferralContactEntity = referralOptionalImePhysician;
        newImePhysician["cityName"] = undefined;
        newImePhysician["addressLine1"] = undefined;
        newImePhysician["addressLine2"] = undefined;
        newImePhysician["zipCode"] = undefined;
        newImePhysician["stateId"] = undefined;

        setReferralImePhysicianFullAddress("");
        props.handleOptionalImePhysicianEdit(newImePhysician);
        setreferralOptionalImePhysician(newImePhysician);
    }

  
    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
    
    const validateIME = (isChange?: boolean) => {
        let errors: IImeReferralFormErrors = {};
        errors = getErrorsListIME();
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidIME = () => {
        let errors: IImeReferralFormErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListIME();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }
    
    const getErrorsListIME = () => { //returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IImeReferralFormErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if (!(referralImeState?.specialtyId == null || referralImeState?.specialtyId !== 0)) {
            if (!props.ime?.specialtyOther) errors.specialtyOther = 'Required';
        }
        
        return errors;
    }

    const handleTextFieldChangeIme = (event) => {
        event.persist();
        let newIme: ImeEntity = referralImeState;
        if (!newIme.referralId) {
            newIme.referralId = props.referralId;
        }

        if (event.target.type == 'checkbox') {
            newIme[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        } else {
            newIme[event.target.id ? event.target.id : event.target.name] =
                event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        }

        if (!newIme.isCoveredLetterRequired || newIme.isCoveredLetterRequired?.toString() == 'false') {
            newIme.isCoverLetterProvidedSeparately = false;
            newIme.isMedicalSummaryNeeded = false;
            newIme.isDetailHistoryNeeded = false;
            newIme.isComplaintRelatedToDate = false;
            newIme.isTreatmentReasonable = false;
            newIme.isClaimantAbleToWork = false;
            newIme.isClaimantReachedMMI = false;
            newIme.isImpairmentRaitingNeeded = false;
            newIme.isFurtherMedicalTreatment = false;
            newIme.otherCommentsForCoverLetter = null;
        }

        if (newIme.languageId !== 0) {
            newIme.otherLanguage = null;
        }

        if (!newIme.translation) {
            newIme.otherLanguage = null;
            newIme.languageId = null;
        }

        if (newIme.specialtyId !== 0) {
            newIme.specialtyOther = null;
        }

        setReferralImeState(newIme);
        props.handleImeEdit(newIme);

        if (hasBeenSubmitted) {
            validateIME(true);
        }
    };

    const handleClearSelectFieldChangeIme = (selectId: string) => {
        let newIme: ImeEntity = referralImeState;
        if (!newIme.referralId) {
            newIme.referralId = props.referralId;
        }

        newIme[selectId] = null;

        if (newIme.languageId !== 0) {
            newIme.otherLanguage = null;
        }

        if (!newIme.translation) {
            newIme.otherLanguage = null;
            newIme.languageId = null;
        }

        if (newIme.specialtyId !== 0) {
            newIme.specialtyOther = null;
        }

        setReferralImeState(newIme);
        props.handleImeEdit(newIme);

        if (hasBeenSubmitted) {
            validateIME(true);
        }
    };

    

    const handleTextFieldChangeTreatingPhysician = (event) => {
        event.persist();
        let newTreatingPhysician: ReferralContactEntity = referralTreatingPhysicianState
            ? referralTreatingPhysicianState
            : new ReferralContactEntity();
        if (!newTreatingPhysician.referralId) {
            newTreatingPhysician.referralId = props.referralId;
        }
        if (!newTreatingPhysician.contactTypeId) {
            newTreatingPhysician.contactTypeId = ReferralContactTypesEnum.TreatingPhysician;
        }
        newTreatingPhysician[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        props.handleTreatingPhysicianEdit(newTreatingPhysician);
        setreferralTreatingPhysicianState(newTreatingPhysician);

        if (hasBeenSubmitted) {
            validateIME(true);
        }
    };

    const handleClearSelectFieldChangeTreatingPhysician = (selectId: string) => {
        let newTreatingPhysician: ReferralContactEntity = referralTreatingPhysicianState
            ? referralTreatingPhysicianState
            : new ReferralContactEntity();
        if (!newTreatingPhysician.referralId) {
            newTreatingPhysician.referralId = props.referralId;
        }
        if (!newTreatingPhysician.contactTypeId) {
            newTreatingPhysician.contactTypeId = ReferralContactTypesEnum.TreatingPhysician;
        }
        newTreatingPhysician[selectId] = null;
        props.handleTreatingPhysicianEdit(newTreatingPhysician);
        setreferralTreatingPhysicianState(newTreatingPhysician);

        if (hasBeenSubmitted) {
            validateIME(true);
        }
    };

    const handleTextFieldChangeOptionalImePhysician = (event) => {
        event.persist();
        let newOptionalPhysician: ReferralContactEntity = referralOptionalImePhysician
            ? referralOptionalImePhysician
            : new ReferralContactEntity();
        if (!newOptionalPhysician.referralId) {
            newOptionalPhysician.referralId = props.referralId;
        }
        if (!newOptionalPhysician.contactTypeId) {
            newOptionalPhysician.contactTypeId = ReferralContactTypesEnum.OptionalPhysician;
        }
        newOptionalPhysician[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setreferralOptionalImePhysician(newOptionalPhysician);
        props.handleOptionalImePhysicianEdit(newOptionalPhysician);

        if (hasBeenSubmitted) {
            validateIME(true);
        }
    };

    const handleClearSelectFieldChangeOptionalImePhysician = (selectId: string) => {
        let newOptionalPhysician: ReferralContactEntity = referralOptionalImePhysician
            ? referralOptionalImePhysician
            : new ReferralContactEntity();
        if (!newOptionalPhysician.referralId) {
            newOptionalPhysician.referralId = props.referralId;
        }
        if (!newOptionalPhysician.contactTypeId) {
            newOptionalPhysician.contactTypeId = ReferralContactTypesEnum.OptionalPhysician;
        }
        newOptionalPhysician[selectId] = null;
        setreferralOptionalImePhysician(newOptionalPhysician);
        props.handleOptionalImePhysicianEdit(newOptionalPhysician);

        if (hasBeenSubmitted) {
            validateIME(true);
        }
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.persist();
        setReferralImeBodyPartsState(event.target.value as number[]);
        props.handleImeBodyPartsEdit(event.target.value as number[]);
    };
    const divStyle = {
        display: 'flex',
        alignItems: 'left',
        borderBottom:"1px #E7EEF1 solid"
      };
   
      const handlePaste = (event) => {
        event.clipboardData.items[0].getAsString(text => {
            console.log(text.length);
          if(text.length>1000)
          {
              toast.warning('⚠️ ' + 'You’ve reached the character limit, please shorten your message');
          }
        })
      }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["Ime_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="Ime-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "Ime_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>
                            Independent Medical Exam (IME)
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {referralImeState ? (
                            <Grid container spacing={3} className={classes.gridContainer}>
                                <Grid item xs={3} container spacing={3}>
                                    <Grid item xs={12}>
                                        <TooltipTextField
                                            type="select"                                            
                                            formControlClass={classes.selectInput}
                                            inputLabelId="demo-simple-select-label"
                                            label="Specialty"
                                            id="specialtyId"
                                            name="specialtyId"
                                            key={referralImeState.specialtyId}
                                            value={
                                                referralImeState.specialtyId || referralImeState.specialtyId === 0
                                                    ? referralImeState.specialtyId
                                                    : ''
                                            }
                                            onChange={(event) => handleTextFieldChangeIme(event)}
                                            zeroIsValid
                                            inputProps={{
                                                endAdornment: (
                                                    <ClearSelect
                                                        shouldRender={referralImeState.specialtyId !== null}
                                                        onClick={() => handleClearSelectFieldChangeIme('specialtyId')}
                                                    />
                                                ),
                                            }}
                                        >
                                            {specialties?.map((specialty, index) => {
                                                return (
                                                    <MenuItem key={specialty.specialtyId} value={specialty.specialtyId}>
                                                        {specialty.specialtyName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TooltipTextField>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        // notShrinkOnDisabled
                                        disabled={
                                            referralImeState.specialtyId == null || referralImeState.specialtyId !== 0
                                        }
                                        label="Specialty - Other"
                                        id="specialtyOther"
                                        onChange={(event) => handleTextFieldChangeIme(event)}
                                        value={referralImeState.specialtyOther ? referralImeState.specialtyOther : ''}
                                        inputProps={{ maxLength: 50 }}
                                        helperText={`${
                                            referralImeState?.specialtyOther
                                                ? referralImeState?.specialtyOther.length
                                                : 0
                                        }/${50} ${errors?.specialtyOther ? errors?.specialtyOther : ''}`}
                                        required={
                                            !(
                                                referralImeState.specialtyId == null ||
                                                referralImeState.specialtyId !== 0
                                            )
                                        }
                                        error={
                                            !!errors?.specialtyOther || referralImeState?.specialtyOther?.length == 0
                                        }
                                        classes={{ root: classes.textField }}
                                        // helperText={errors?.specialtyOther}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={'rushRequest'}
                                                name={'rushRequest'}
                                                disableRipple
                                                checked={referralImeState.rushRequest}
                                                onClick={(event) => handleTextFieldChangeIme(event)}
                                            />
                                        }
                                        label={<Typography variant="body2">Rush Request</Typography>}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TooltipTextField
                                        inputProps={{ maxLength: 500 }}
                                        maxRows={6}
                                        multiline={true}
                                        label="Reason for IME"
                                        id="reasonForIME"
                                        onChange={(event) => handleTextFieldChangeIme(event)}
                                        value={referralImeState.reasonForIME ? referralImeState.reasonForIME : ''}
                                        helperText={`${
                                            referralImeState?.reasonForIME ? referralImeState?.reasonForIME.length : 0
                                        }/${500}`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.selectInput}>
                                        <TooltipTextField
                                            multiline={true}
                                            label="History of Complaint"
                                            id="historyOfComplaint"
                                            onChange={(event) => handleTextFieldChangeIme(event)}
                                            value={
                                                referralImeState?.historyOfComplaint
                                                    ? referralImeState?.historyOfComplaint
                                                    : ''
                                            }
                                            inputProps={{ maxLength: 500 }}
                                            helperText={`${
                                                referralImeState?.historyOfComplaint
                                                    ? referralImeState?.historyOfComplaint.length
                                                    : 0
                                            }/${500}`}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.selectInput}>
                                        <TooltipTextField
                                            multiline={true}
                                            label="Any Case Management History"
                                            id="anyCaseManagementHistory"
                                            onChange={(event) => handleTextFieldChangeIme(event)}
                                            value={
                                                referralImeState.anyCaseManagementHistory
                                                    ? referralImeState.anyCaseManagementHistory
                                                    : ''
                                            }
                                            inputProps={{ maxLength: 500 }}
                                            helperText={`${
                                                referralImeState?.anyCaseManagementHistory
                                                    ? referralImeState?.anyCaseManagementHistory.length
                                                    : 0
                                            }/${500}`}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        type="select"
                                        formControlClass={classes.selectInput}
                                        label="Specific Body Parts"
                                        inputLabelId="demo-mutiple-checkbox-label"
                                        id="input-specific-body-parts-id"
                                        selectProps={{
                                            multiple: true,
                                            input: <Input />,
                                            renderValue: (selected) =>
                                                (selected as number[])
                                                    .map(
                                                        (x) =>
                                                            specificBodyParts.filter(
                                                                (y) => y.specificBodyPartId == x,
                                                            )[0].specificBodyPartName,
                                                    )
                                                    .join(', '),
                                            MenuProps: MenuProps,
                                        }}
                                        value={referralImeBodyPartsState ? referralImeBodyPartsState : ''}
                                        onChange={handleChange}
                                    >
                                        {specificBodyParts?.map((bodyPart) => {
                                            return (
                                                <MenuItem
                                                    key={bodyPart.specificBodyPartId}
                                                    value={bodyPart.specificBodyPartId}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            referralImeBodyPartsState.indexOf(
                                                                bodyPart.specificBodyPartId,
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText primary={bodyPart.specificBodyPartName} />
                                                </MenuItem>
                                            );
                                        })}
                                    </TooltipTextField>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Impairment Rating Needed</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            name="impairmentRatingNeeded"
                                            onChange={(event) => handleTextFieldChangeIme(event)}
                                            value={referralImeState?.impairmentRatingNeeded ? true : false}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio color="primary" />}
                                                label="No"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Patient will be using transportation</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            name="transportation"
                                            onChange={(event) => handleTextFieldChangeIme(event)}
                                            value={referralImeState?.transportation ? true : false}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio color="primary" />}
                                                label="No"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Patient will be using a translator</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="position"
                                            name="translation"
                                            onChange={(event) => handleTextFieldChangeIme(event)}
                                            value={referralImeState?.translation ? true : false}
                                        >
                                            <FormControlLabel
                                                value={true}
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={false}
                                                control={<Radio color="primary" />}
                                                label="No"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} container spacing={3}>
                                    <Grid item xs={12}>
                                        {!referralImeState?.translation 
                                            ? (<TextField id="label-language" label="Language" disabled />)
                                            : (
                                                <TooltipTextField
                                                    type="select"
                                                    formControlClass={classes.selectInput}
                                                    label="Language"
                                                    inputLabelId="demo-simple-select-label"
                                                    id="languageId"
                                                    name="languageId"
                                                    key={referralImeState.languageId}
                                                    value={
                                                        referralImeState.languageId 
                                                            ? referralImeState.languageId
                                                            : ''
                                                    }
                                                    onChange={(event) => handleTextFieldChangeIme(event)}
                                                    disabled={!referralImeState?.translation}
                                                    zeroIsValid
                                                    inputProps={{
                                                        endAdornment: (
                                                            <ClearSelect
                                                                shouldRender={!isNullOrEmpty(referralImeState?.languageId)}
                                                                onClick={() => handleClearSelectFieldChangeIme('languageId')}
                                                            />
                                                        ),
                                                    }}
                                                >
                                                    {languages?.map((language, index) => {
                                                        return (
                                                            <MenuItem key={language.languageId} value={language.languageId}>
                                                                {language.languageName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </TooltipTextField>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid item xs={12}>
                                        {!!referralImeState.languageId ||
                                        !referralImeState.translation ||
                                        referralImeState.languageId !== 0
                                        ? (<TextField id="label-other-language" label="Other Language" disabled />)
                                        : (
                                            <TooltipTextField
                                                label="Other Language"
                                                id="otherLanguage"
                                                disabled={
                                                    !!referralImeState.languageId ||
                                                    !referralImeState.translation ||
                                                    referralImeState.languageId !== 0
                                                }
                                                onChange={(event) => handleTextFieldChangeIme(event)}
                                                value={referralImeState.otherLanguage ? referralImeState.otherLanguage : ''}
                                            />
                                        )}                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.selectInput}>
                                        <TooltipTextField
                                            maxRows={4}
                                            multiline={true}
                                            label="Additional Comments"
                                            id="otherComments"
                                            onChange={(event) => handleTextFieldChangeIme(event)}
                                            value={referralImeState.otherComments ? referralImeState.otherComments : ''}
                                            inputProps={{ maxLength: 1000 }}
                                            helperText={`${
                                                referralImeState?.otherComments
                                                    ? referralImeState?.otherComments.length
                                                    : 0
                                            }/${1000}`}
                                            onPaste={handlePaste}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ) : (
                            <EmptyDataLabel></EmptyDataLabel>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["ImeTreatingPhysician_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="ImeTreatingPhysician-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "ImeTreatingPhysician_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>
                            Treating Physician
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {referralTreatingPhysicianState ? (
                            <Grid container spacing={3} className={classes.gridContainer}>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        key="TreatingFirstName"
                                        label="First Name"
                                        id="firstName"
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.firstName
                                                : ''
                                        }                                        
                                        helperText={errors?.treatingPhysicianFirstName}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SearchAddressAsync passAddress ={passAddress} states={props.states} />
                                </Grid>
                                <Grid item xs={6} alignItems="flex-start" >
                                          
                                </Grid>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        key="TreatingMI"
                                        label="Middle initial"
                                        id="middleInitial"
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.middleInitial
                                                : ''
                                        }
                                        inputProps={{ maxLength: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <AddressValue 
                                        key="TreatingSearchAddressResult" 
                                        id="TreatingSearchAddressResult" 
                                        address={referralTreatingPhysicianFullAddress} 
                                        handleClear={handleClear} 
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    
                                </Grid>
                                <Grid item xs={3}>
                                    
                                </Grid>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        key="TreatingLastName"
                                        label="Last Name"
                                        id="lastName"
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.lastName
                                                : ''
                                        }                                        
                                        helperText={errors?.treatingPhysicianLastName}
                                    />
                                </Grid>                                
                                <Grid item xs={3}>
                                <InputMask
                                        mask="(999)-9999999"
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.primaryPhoneNumber
                                                : ''
                                        }
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => (
                                            <TooltipTextField
                                                key="TreatingPrimary"
                                                label="Primary Phone"
                                                id="primaryPhoneNumber"
                                                //error={!!errors?.treatingPhysicianPrimaryPhoneNumber}
                                                //helperText={errors?.treatingPhysicianPrimaryPhoneNumber}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={3}>
                                <TooltipTextField
                                        key="TreatingPhoneExt"
                                        label="Ext."
                                        id="primaryPhoneExtension"
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.primaryPhoneExtension
                                                : ''
                                        }
                                    />
                                    
                                </Grid>
                                <Grid item xs={3}>
                                    
                                </Grid>
                                <Grid item xs={3}>
                                    <InputMask
                                        mask="9999999999"
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.treatingPhysicianNPI
                                                : ''
                                        }
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => (
                                            <TooltipTextField
                                                key="TreatingNPI"
                                                label="Physician NPI"
                                                id="treatingPhysicianNPI"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={3}>
                                <InputMask
                                        mask="(999)-9999999"
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.secondaryPhoneNumber
                                                : ''
                                        }
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => (
                                            <TooltipTextField
                                                key="TreatingSecondary"
                                                label="Secondary Phone"
                                                id="secondaryPhoneNumber"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={3}>
                                <TooltipTextField
                                        key="TreatingSecondaryExt"
                                        label="Ext."
                                        id="secondaryPhoneExtension"
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.secondaryPhoneExtension
                                                : ''
                                        }
                                    />
                                    
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        key="TreatingEmail"
                                        label="Email"
                                        id="email"
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        value={
                                            referralTreatingPhysicianState ? referralTreatingPhysicianState.email : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                <InputMask
                                        mask="(999)-9999999"
                                        value={referralTreatingPhysicianState ? referralTreatingPhysicianState.fax : ''}
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => <TooltipTextField key="TreatingFix" label="Fax" id="fax" />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={9}>
                                    {/* Empty space */}
                                </Grid>
                                <Grid item xs={12}>
                                    <TooltipTextField
                                        key="TreatingAdditionalComments"
                                        maxRows={4}
                                        multiline={true}
                                        label="Additional Comments"
                                        id="comments"
                                        onChange={(event) => handleTextFieldChangeTreatingPhysician(event)}
                                        value={
                                            referralTreatingPhysicianState
                                                ? referralTreatingPhysicianState.comments
                                                : ''
                                        }
                                        inputProps={{ maxLength: 250 }}
                                        helperText={`${
                                            referralTreatingPhysicianState?.comments
                                                ? referralTreatingPhysicianState?.comments.length
                                                : 0
                                        }/${250}`}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <EmptyDataLabel />
                        )}
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["OptionalImePhysician_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="OptionalImePhysician-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "OptionalImePhysician_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>
                            Optional Requested IME Physician
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {referralOptionalImePhysician ? (
                            <Grid container spacing={3} className={classes.gridContainer}>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        key="FirstName"
                                        label="First Name"
                                        id="firstName"
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        value={
                                            referralOptionalImePhysician ? referralOptionalImePhysician.firstName : ''
                                        }                                        
                                        helperText={errors?.optionalPhysicianFirstName}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <SearchAddressAsync passAddress={passAddressIME} states={props.states} />
                                </Grid>
                                <Grid item xs={6}>

                                </Grid>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        label="Middle initial"
                                        id="middleInitial"
                                        type=""
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        value={
                                            referralOptionalImePhysician
                                                ? referralOptionalImePhysician.middleInitial
                                                : ''
                                        }
                                        inputProps={{ maxLength: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <AddressValue 
                                            key="ImeSearchAddressResult" 
                                            id="ImeSearchAddressResult" 
                                            address={referralImePhysicianFullAddress} 
                                            handleClear={handleClearIME} 
                                        />
                                </Grid>
                                <Grid item xs={3}>
                                    
                                </Grid>
                                <Grid item xs={3}>
                                    
                                </Grid>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        label="Last Name"
                                        id="lastName"
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        value={
                                            referralOptionalImePhysician ? referralOptionalImePhysician.lastName : ''
                                        }                                        
                                        helperText={errors?.optionalPhysicianLastName}
                                    />
                                </Grid>                                
                                <Grid item xs={3}>
                                <InputMask
                                        mask="(999)-9999999"
                                        value={
                                            referralOptionalImePhysician
                                                ? referralOptionalImePhysician.primaryPhoneNumber
                                                : ''
                                        }
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => <TooltipTextField label="Primary Phone" id="primaryPhoneNumber" />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={3}>
                                <TooltipTextField
                                        label="Ext."
                                        id="primaryPhoneExtension"
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        value={
                                            referralOptionalImePhysician
                                                ? referralOptionalImePhysician.primaryPhoneExtension
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    
                                </Grid>
                                <Grid item xs={3}>
                                    <InputMask
                                        mask="9999999999"
                                        value={
                                            referralOptionalImePhysician
                                                ? referralOptionalImePhysician.optionalPhysicianNPI
                                                : ''
                                        }
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => <TooltipTextField label="Physician NPI" id="optionalPhysicianNPI" />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={3}>
                                <InputMask
                                        mask="(999)-9999999"
                                        value={
                                            referralOptionalImePhysician
                                                ? referralOptionalImePhysician.secondaryPhoneNumber
                                                : ''
                                        }
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => <TooltipTextField label="Secondary Phone" id="secondaryPhoneNumber" />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={3}>
                                <TooltipTextField
                                        label="Ext."
                                        id="secondaryPhoneExtension"
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        value={
                                            referralOptionalImePhysician
                                                ? referralOptionalImePhysician.secondaryPhoneExtension
                                                : ''
                                        }
                                    />                                    
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={3}>
                                    <TooltipTextField
                                        label="Email"
                                        id="email"
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        value={referralOptionalImePhysician ? referralOptionalImePhysician.email : ''}
                                        //error={!!errors?.optionalPhysicianEmail}
                                        //helperText={errors?.optionalPhysicianEmail}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                <InputMask
                                        mask="(999)-9999999"
                                        value={referralOptionalImePhysician ? referralOptionalImePhysician.fax : ''}
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        disabled={false}
                                        maskChar=" "
                                    >
                                        {() => <TooltipTextField label="Fax" id="fax" />}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={9}>
                                    {/* Empty space */}
                                </Grid>
                                <Grid item xs={12}>
                                    <TooltipTextField
                                        key="AdditionalComments"
                                        maxRows={4}
                                        multiline={true}
                                        label="Additional Comments"
                                        id="comments"
                                        onChange={(event) => handleTextFieldChangeOptionalImePhysician(event)}
                                        value={
                                            referralOptionalImePhysician ? referralOptionalImePhysician.comments : ''
                                        }
                                        inputProps={{ maxLength: 250 }}
                                        helperText={`${
                                            referralOptionalImePhysician?.comments
                                                ? referralOptionalImePhysician?.comments.length
                                                : 0
                                        }/${250}`}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <EmptyDataLabel />
                        )}
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["CoverLetter_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="CoverLetter-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "CoverLetter_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>
                            Cover Letter
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ImeCoverLetter
                            coverLetterState={props.coverLetterState}
                            handleImeCoverLetterEdit={props.handleImeCoverLetterEdit}
                            handleTextFieldChangeIme={handleTextFieldChangeIme}
                            handleUpdateImeCoverLetter={props.handleUpdateImeCoverLetter}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {Dialog}
        </Fragment>
    );
};
