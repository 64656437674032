import React, { FunctionComponent } from 'react';
import { Typography, Grid, Paper, List, ListSubheader, RadioGroup, ThemeProvider } from '@material-ui/core';
import styles, { medicalServiceTheme } from './ReferralService.Styles';
import ServiceDataModel from '../../../../data-models/Service.DataModel';
import { ReferralServiceList } from './ReferralServiceList.Component';
import { ReferralVendorList } from './ReferralVendorList.Component';
import VendorEntity from '../../../../entities/Vendor.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';

interface IReferralServiceProps {
    services: Array<ServiceDataModel>;
    vendors: Array<VendorEntity>;
    handleSelectServiceType: (selectedServiceTypeId: number[]) => Promise<void>;
    handleSelectVendor: (selectedVendorId: number) => void;
    serviceTypeId?: Array<number>;
    vendorId?: number;
}
export const ReferralService: FunctionComponent<IReferralServiceProps> = ({
    services,
    vendors,
    handleSelectServiceType,
    handleSelectVendor,
    serviceTypeId,
    vendorId,
}: IReferralServiceProps) => {
    const classes = styles();

    const [serviceTypeSelected, setServiceTypeSelected] = React.useState<Array<number>>([]);
    const [serviceSelected, setServiceSelected] = React.useState<number>(null);
    const myHandleServiceTypesSelected = (serviceTypeSelected: number[]) => {        
        setVendorSelected(null);
        handleSelectVendor(null);
                
        setServiceTypeSelected(serviceTypeSelected);
        handleSelectServiceType(serviceTypeSelected);

        if (serviceTypeSelected.length === 0) {
            handleSelectServiceType([]);
            handleSelectVendor(null);
        }
    };
    const [vendorSelected, setVendorSelected] = React.useState(0);
    const myHandleVendorSelected = (vendorSelected) => {        
        setVendorSelected(vendorSelected);
        handleSelectVendor(vendorSelected);
    };

    if (serviceTypeId && vendorId && serviceTypeId[0] !== serviceTypeSelected[0] && vendorId !== vendorSelected) {        
        setServiceTypeSelected(serviceTypeId);
        setVendorSelected(vendorId);
    }

    return (
        <ThemeProvider theme={medicalServiceTheme}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    className={classes.listHeader}
                                >
                                    <Typography variant="subtitle2" className={classes.paperTitle}>
                                        Service Type
                                    </Typography>
                                </ListSubheader>
                            }
                            className={classes.root}
                        >
                            {services.length > 0 ? (
                                <RadioGroup aria-label="service" name="service" value={serviceTypeSelected}>
                                    {services.map((service, index) => {
                                        return (
                                            <ReferralServiceList
                                                //parentServiceTypeSelected={serviceTypeSelected}
                                                handleServiceTypesSelected={myHandleServiceTypesSelected}
                                                key={service.service.serviceId}
                                                service={service}
                                                expanded={
                                                    service.serviceTypes.findIndex(
                                                        (x) => x.serviceTypeId == serviceTypeSelected[0],
                                                    ) !== -1
                                                }
                                                setServiceSelected={setServiceSelected}
                                                serviceSelected={serviceSelected}
                                                serviceTypesSelected={serviceTypeSelected}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            ) : (
                                <EmptyDataLabel />
                            )}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    className={classes.listHeader}
                                >
                                    <Typography variant="subtitle2" className={classes.paperTitle}>
                                        Vendor
                                    </Typography>
                                    <Typography variant="caption" className={classes.listHeaderSubTitle}>
                                        {' '}
                                        Please select a Service Type first to show the available vendors
                                    </Typography>
                                </ListSubheader>
                            }
                            className={classes.root}
                        >
                            {vendors && vendors.length != 0 ? (
                                <RadioGroup
                                    className={classes.vendorsGroup}
                                    onClick={(event) =>
                                        myHandleVendorSelected(+(event.target as HTMLInputElement).value)
                                    }
                                    aria-label="vendor"
                                    name="vendor"
                                    value={vendorSelected}
                                >
                                    <ReferralVendorList vendors={vendors} />
                                </RadioGroup>
                            ) : (
                                <EmptyDataLabel />
                            )}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};
