import { Button, Paper, Typography } from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import React, { FunctionComponent } from "react";
import styles from './VendorAuthCredentials.Styles';
import MaterialTable from "../../generic-components/material-table/MaterialTable.Component";
import StateEntity from "../../../entities/State.Entity";
import AuthenticationTypeEntity from "../../../entities/AuthenticationType.Entity";
import VendorAuthCredentialEntity from "../../../entities/VendorAuthCredential.Entity";
import { AddVendorAuthCredential } from "../add-vendor-auth-credential/AddVendorAuthCredential.Component";
import VendorAuthCredentialDataModel from "../../../data-models/VendorAuthCredential.DataModel";

interface VendorAuthCredentialsProps{
    vendorAuthCredentials: Array<VendorAuthCredentialDataModel>;
    newVendorAuthCredential: VendorAuthCredentialEntity,
    handleVendorAuthCredentialEdit: (newVendor: VendorAuthCredentialEntity) => void;
    createVendorAuthCredential: () => void;
    states: Array<StateEntity>;
    authenticationTypes: Array<AuthenticationTypeEntity>;
    vendorId: number;
}

export const VendorAuthCredentials: FunctionComponent<VendorAuthCredentialsProps> = (props) => {

    const classes = styles();
    
    const [filter, setFilter] = React.useState<boolean>(false);
    const [showAddVendorAuthCredential, setShowAddVendorAuthCredential] = React.useState<boolean>(false);

    const clientsTableProps: MaterialTableProps<VendorAuthCredentialDataModel> = {
        columns: [
            {
                title: 'Auth Type',
                field: 'authenticationType',
            },
            {
                title: 'Auth Key Type',
                field: 'authKeyType',
            },
            {
                title: 'Auth Key',
                field: 'authKey',
            },
            {
                title: 'Auth Value Secret ID',
                field: 'authValueSecretID'
            },
            {
                title: 'Token EndPoint',
                field: 'tokenEndpoint'
            },
            {
                title: 'Scope',
                field: 'scope',
            },
            {
                title: 'Resource',
                field: 'resource',
            },
            {
                title: 'CommunicationType',
                field: 'communicationType',
            }
        ],
        data: props.vendorAuthCredentials ? props.vendorAuthCredentials : [],
        options: {
            filtering: filter,
            showTitle: false,
            paging: props.vendorAuthCredentials ? props.vendorAuthCredentials.length > 10 : false,
            toolbar: true,
        },
        style:{
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions:[
            {
              icon:'Add Vendor',
              tooltip: 'Add Vendor',
              position: 'toolbar',
              onClick: (event, rowData) => {
                  //setShowAddVendorAuthCredential(true);
              }
            }
          ],
        components:{
            Action: props => 
                <Button {...props}
                    className={classes.button}
                    onClick={() => setShowAddVendorAuthCredential(true)} 
                >
                    Add Auth Credential
                </Button>
        },
        title: 'Vendor Auth Credential List'
    }

    return (
        <Paper className={classes.paper}>
                {showAddVendorAuthCredential ? 
                <AddVendorAuthCredential
                    newVendorAuthCredential={props.newVendorAuthCredential}
                    handleVendorAuthCredentialEdit={props.handleVendorAuthCredentialEdit}
                    createVendorAuthCredential={props.createVendorAuthCredential}
                    states={props.states}
                    authenticationTypes={props.authenticationTypes}
                    cancel={() => setShowAddVendorAuthCredential(false)}
                    vendorId={props.vendorId}
                />
                    : 
                        <MaterialTable tableProps={clientsTableProps} /> 
                }
        </Paper>
    )        
}