import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface WarningLeavingPageProps {
    referralStepIndex: number;
}

const WarningLeavingPage: React.FC<WarningLeavingPageProps> = ({ referralStepIndex }) => {
    const history = useHistory();
    const unblockRef = React.useRef<any>(null);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (referralStepIndex !== 3 && referralStepIndex!==0) {
                event.preventDefault();
                event.returnValue = "";
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        unblockRef.current = history.block(() => {
            if (referralStepIndex !== 3  && referralStepIndex!==0) {
                return "You have not submitted the referral, are you sure you want to leave this page?";
            }
            return false;
        });

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            if (unblockRef.current) {
                unblockRef.current();
            }
        };
    }, [referralStepIndex, history]);

    return null;
};

export default WarningLeavingPage;
